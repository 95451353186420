.add-user-data-main .user-data-head {
    border: none;
}
.user-data-head .MuiDataGrid-main .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeadersInner {
    width: 100%;
}
.user-data-head .MuiDataGrid-columnHeaderTitle {
    font-size: 15px;
    line-height: 20px;
    color: #495463;
    font-family: "Poppins-Medium";
    text-overflow: initial;
    overflow: initial;
    /* white-space: initial; */
}