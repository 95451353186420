.admin-private-main {
    padding: 10px 0px 0px;
}
.admin-text-field-main {
    margin-bottom: 16px;
    min-height: 65px;
    height: auto;
}
.privat-sale-datepicker input {
    padding: 8.5px 15px;
}
.admin-text-field-main .admin-textfield {
    width: 100%;
}
.admin-text-field-main .admin-textfield .MuiInputBase-root {
    border-radius: 3px;
}
.admin-text-field-main .admin-textfield input {
    max-width: 523px;
    padding: 9px 15px;
    color: rgba(0, 0, 0, 0.6) !important;
    border: 1px solid #74540b42;
    width: 100%;
    border-radius: 6px;
  }
  .presale-input .admin-textfield input {
    max-width: 100% !important;
}  