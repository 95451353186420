/* footer css */

.bottom-bg-footer {
  /* background-color: #F5AA16; */
  /* background: #2c2968; */
  background: linear-gradient(72.47deg, #18c77763 22.16%, #3ca4b5d6 76.47%);

  padding: 2px 0px;
}

.bottom-bg-footer .copyright-title {
  font-size: 16px;
  line-height: 26px;
  /* color: #ddd; */
  color: #000000;
  font-family: "Poppins-Medium";
}

.footer-set {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-bg-footer .pd-footer-copy {
  /* padding: 19px 0px 18px 0px; */
  /* text-align: center; */
}

.footer-set ul {
  display: flex;
}

.footer-ul li {
  white-space: nowrap;
  padding: 0px;
}

.footer-ul li a {
  padding: 0px 10px;
  text-decoration: none;
  font-size: 16px;
  line-height: 26px;
  /* color: #ddd; */
  color: #000000;

  font-family: "Poppins-Medium";
}

.footer-ul li a:hover {
  /* color:#74540b; */
  color: #000000;
}

/* end footer */

/* header css start user */

.marquee-text {
  font-size: 14px;
  font-family: "Poppins-Regular";
  color: #32b0c4;
  max-width: 400px;
  width: 400px;
  display: flex;
}

.user-header-main {
  background: #ffffff !important;
  position: fixed;
  top: 20px;
  /* left: 290px; */
  left: 310px;
  right: 20px;
  z-index: 9;
  min-height: 58px;
  padding: 12.5px 20px 12.5px 20px !important;
  border-bottom: 1px solid #e5e7eb;

  /* 01/08/2023 */
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-right {
  display: flex;
  align-items: center;
}

.res-logo-icon {
  display: none;
}

.res-set-btn-deposit-link {
  display: none;
}

.resposive-dropdown .text-decoration .resposive-dropdown-main {
  background: transparent;
}

.resposive-dropdown
  .text-decoration
  .resposive-dropdown-main
  .primary-border-btn {
  background: transparent;
  color: #5d5a68;
  border: 1px solid;
  padding: 13px 20px;
  border: 0;
  border-bottom: 1px solid #74540b;
  border-radius: 0;
  font-family: "Poppins-Regular";
}

.resposive-dropdown
  .text-decoration:last-child
  .resposive-dropdown-main
  .primary-border-btn {
  border-bottom: 0;
}

.flex-text {
  display: none;
}

.admin-header-profile-box button {
  padding: 0;
}

/* user css end */

/* user sidebarbar start */

.user-sidebar-main {
  /* background: linear-gradient(157deg, #05225c, #2c59b1 553%); */
  height: 100vh;
  left: 0;
  overflow: initial;
  padding: 0px 0px !important;
  position: fixed;
  top: 0;
  /* width: 290px; */
  width: 290px;
  z-index: 999;
  border-right: 1px solid #e5e7eb;
  background: #ffffff;
}

.user-sidebar-logo-main {
  text-align: left !important;
  /* height: 67px; */
  border-bottom: 1px solid #e5e7eb;
  background: #ffffff !important;
  /* padding: 20px; */
  padding: 5px;
}

.set-logo-text-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
}

.user-sidebar-logo {
  /* height: 50px !important; */
  /* max-width: 190px !important; */
  max-width: 125px !important;
  margin-top: 0px;
}

.admin-sidebar-list-main-user {
  margin-top: 0px;
  height: 100vh;
}

.user-sidebar-link.active {
  padding: 4px 15px;
  margin-left: 0px;
}
.user-sidebar-link {
  min-height: 45px;
}

.user-sidebar-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.user-dashboard-header {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  color: black !important;
  display: flex;
  padding: 10px 20px 10px 20px;
  margin-left: 0px;
  align-items: start;
  width: 100%;
  text-decoration: none;
}

.admin-sidebar-list-main-user .user-sidebar-list-main {
  padding: 10px 15px;
  height: 100vh;
  overflow: auto;
}

/* width */
.admin-sidebar-list-main-user .user-sidebar-list-main::-webkit-scrollbar,
.cus-data-grid .MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.admin-sidebar-list-main-user .user-sidebar-list-main::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.admin-sidebar-list-main-user .user-sidebar-list-main::-webkit-scrollbar-thumb,
.cus-data-grid .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #d3d6da;
}

/* Handle on hover */
.admin-sidebar-list-main-user
  .user-sidebar-list-main::-webkit-scrollbar-thumb:hover,
.cus-data-grid .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.mobile-menu-btn .toggle-btn {
  filter: invert();
}

/* sidebar css end */
