.admin-dash-main .css-1gsv261 {
  border: none;
}
.tab-label-dash .MuiTabs-scroller .MuiTabs-flexContainer button {
  font-size: 14px;
  line-height: 18px;
  font-family: "Poppins-Regular";
}

.tab-label-dash .css-1aquho2-MuiTabs-indicator {
  /* background-color: #bda07e !important; */
  background: transparent linear-gradient(90deg, #1acb41 0%, #7ed947 100%) 0% 0%;
}

.tab-label-dash .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #20ac2f;
}
.tab-one-dash .css-19kzrtu {
  padding: 10px 0 0 0 !important;
}
/* .dashboard-content .admin-page-title { */
/* .admin-dash-main .admin-page-title {
    color: #111827;
    font-size: 26px;
    line-height: 39px;
    font-family: "Poppins-SemiBold";
    margin-bottom: 20px;
  } */
/* .grid-main .grid-item {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0px;
    padding: 0px;
  } */
.admin-dashboad-row {
  margin-bottom: 24px;
}

.admin-dashboard-box2 .user-dashboard-inner-box {
  height: 100px !important;
  /* margin-bottom: 15px; */
  transition: all 0.5s;
  display: block;
}

.admin-dashboard-box2 .user-dashboard-inner-box:hover {
  transform: scale(1.04);
}
.user-dashboard-inner-box {
  /* background: transparent linear-gradient(132deg, #1acb41 0%, #7ed947 100%) 0% 0%; */
  background: #ffffff;
  box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
  /* box-shadow: 0px 3px 6px #089CBC1A; */
  /* border: 1px solid #D4F6FE; */
  min-height: 95px !important;
  /* margin-bottom: 15px; */
}
.admin-dashboard-inner-box .user-dash-text {
  color: #5d5a68 !important;
  font-size: 13px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  white-space: nowrap;
}
.admin-dashboard-inner-box .user-details-text {
  color: #5d5a68 !important;
  font-size: 15px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  white-space: nowrap;
}
.admin-dashboard-inner-box .user-dash-price {
  color: #5d5a68 !important;
  font-size: 18px;
  line-height: 24px;
  font-family: "Poppins-Medium";
}
.admin-dashboard-inner-box .user-details-price {
  color: #5d5a68 !important;
  font-size: 18px;
  line-height: 24px;
  font-family: "Poppins-Medium";
}

/* .dash-icon-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: hsla(0, 0%, 100%, 0.2);
  border-radius: 50px;
  flex: 0 0 40px;
} */
.dash-icons {
  height: 22px;
  width: 22px;
  object-fit: contain;
}
/* .dashboard-containt-main {
  background-color: #ffffff;
  min-height: 100vh;
  padding: 88px 24px 24px 24px;
} */
/* .dashboard-right-main {
  flex: 1 1;
} */
.admin-dashboard-inner-box {
  /* border: 1px solid #D4F6FE; */
  max-width: 100%;
  width: 100%;
  /* height: 90px; */
  /* border-radius: 6px; */
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
}

/* .admin-dashboard-inner-box .admin-dash-text {
  color: #6b7280;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
} */

/* .admin-dashboard-inner-box .admin-dash-price {
  color: #111827;
  font-size: 30px;
  line-height: 46px;
  font-family: "Poppins-Medium";
} */
.tab-two-dash .css-19kzrtu {
  padding: 10px 0 0 0 !important;
}
/* .dashboard-left-main {
  flex: 0 0 260px;
  max-width: 260px;
  width: 100%;
  transition: 0.5s all;
} */
/* .custom-card {
  border: 1px solid #d7d7d7;
  border-radius: 6px;
  box-shadow: 0 3px 6px #d5d2d2;
  padding: 0 15px 15px;
} */
/* .user-head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
} */

.user-head-title h2 {
  line-height: 19px;
}
/* .user-title-row, .withdrawal-head-title {
  margin-bottom: 0;
} */
/* .card-main {
  box-shadow: 0 3px 6px #d5d2d2;
  padding: 15px;
  border: 1px solid #b3b3b387;
  border-radius: 9px;
} */
.charttitle {
  font-family: "Poppins-Medium";
  border-bottom: 1px solid #c6b1b157;
  padding-bottom: 15px;
}
.xaxis-main .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick text {
  font-size: 11px;
  font-family: "Poppins-Regular";
}

.yaxis-main .recharts-cartesian-axis-tick text {
  font-size: 12px;
  font-family: "Poppins-Regular";
}

.line-chart-main .recharts-label-list text {
  font-family: "Poppins-Regular";
  font-size: 8px;
}
.set-chart-drop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chart-drop .MuiOutlinedInput-input {
  padding: 8px 15px;
  border: 1px solid #d1d5db;
}

.chart-drop .MuiSelect-select {
  width: 150px !important;
  min-width: 150px !important;
}
/* fieldset {
  border-color: transparent!important;
  top: 0!important;
} */
.admin-dash-left {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: center; */
  /* margin-bottom: 12px; */
}
.presale-box-wrap {
  padding: 30px 20px;
}
.timer-main {
  display: flex;
  margin-bottom: 20px;
}
.time-box {
  width: 35px;
  height: 35px;
  background: #e8e6fc;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* margin-bottom: 4px; */
  border-radius: 6px;
}
.timer-main .time-box:last-child {
  margin-right: 0;
}
.time-box .time-title {
  font-size: 14px;
  line-height: 12px;
  font-family: "Poppins-Bold";
  color: #5b5865;
  margin: 0;
}
.time-text {
  font-size: 10px;
  line-height: 17px;
  font-family: "Poppins-Regular";
  color: rgba(47, 43, 61, 0.78);
  text-transform: uppercase;
}
.phase-price-title {
  color: rgba(47, 43, 61, 0.78);
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Regular";
  text-align: center !important;
  /* margin: 0 0 5px 0px !important; */
}
.phase-value {
  color: #5b5865;
  font-size: 14px;
  line-height: 19px;
  font-family: "Poppins-Bold";
  text-align: center;
}
.phase-price-box {
  /* background: #EEFCFF;
    border-radius: 6px; */
  /* padding: 20px 40px; */
  /* min-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center; */
  /* margin-bottom: 20px;
    margin-top: 10px; */
}
.progress-value-text {
  color: #52ea9c;
  font-size: 10px;
  line-height: 19px;
  font-family: "Poppins-Regular";
}
.progress-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.progressbar-main {
  background: #eefcff;
  height: 10px;
  width: 100%;
  position: relative;
}
.progressbar-inner {
  background: transparent
    linear-gradient(90deg, #089cbc 0%, #52ea9c 100%, #000000 100%) 0% 0%
    no-repeat padding-box;
  height: 10px;
  width: 50%;
}
.prog-show {
  width: 27px;
  height: 17px;
  background: #004554;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.prog-show::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 3px 7px 3px;
  border-color: transparent transparent #004554 transparent;
  /* transform: rotate(0deg); */
  position: absolute;
  left: 50%;
  top: -7px;
  transform: translateX(-50%);
}
.per-text {
  color: #ffffff;
  font-size: 8px;
  line-height: 19px;
  font-family: "Poppins-Regular";
}
.token-sold-main .phase-price-title {
  text-align: left;
}
/* .token-sold-main{
  margin-top: 41px;
} */
.token-sold-values {
  color: #5b5865;
  font-size: 12px;
  line-height: 14px;
  font-family: "Poppins-Bold";
  margin-top: 3px;
  text-align: center;
}
.card-row {
  display: flex;
  flex-wrap: wrap;
  /* flex: 1 1 auto; */
  margin: 0 -12px;
  /* padding: 20px; */
}
.card-col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
  width: 100%;
  padding: 12px;
}
.card-icon {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background: #e8e6fc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.card-title-icon {
  width: 18px;
  height: 18px;
}
.card-title-main {
  display: flex;
  align-items: center;
}
.card-dash-text {
  color: rgba(47, 43, 61, 0.78) !important;
  font-size: 13px;
  line-height: 18px;
  font-family: "Poppins-Regular";
}
.card-box .admin-dash-price {
  font-size: 22px;
  line-height: 30px;
  color: rgba(47, 43, 61, 0.78);
  margin: 12px 0;
  font-family: "Poppins-Medium";
}
.card-progress-main {
  width: 100%;
  height: 4px;
  background-color: #efeef0;
}
.card-progress-main .card-progress-inner {
  width: 100%;
  height: 4px;
  /* background-color: #7367f0; */
  background-color: #00cfe8;
}
.card-progress-main .purchase-progress-inner {
  background-color: #00cfe8;
  width: 100%;
}
.purchase-box .card-icon {
  background: #d6f7fb;
}
.token-box .purchase-progress-inner {
  /* background-color: #ea5455; */
  background-color: #00cfe8;
  width: 100%;
}
.token-box .card-icon {
  background: #fbe3e4;
}
.deposit-box .purchase-progress-inner {
  /* background-color: #ff9e43; */
  background-color: #00cfe8;
  width: 100%;
}
.deposit-box .card-icon {
  background: #ffefe1;
}
.non-deposit-box .purchase-progress-inner {
  /* background-color: #7367f0; */
  background-color: #00cfe8;
  width: 100%;
}
.non-deposit-box .card-icon {
  background: rgb(115 103 240 / 20%);
}
.card-box {
  margin-bottom: 20px;
}
/* .presale-box-sec{
  min-height: 316px;
  padding: 20px;
} */
.presale-timer-main {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  /* flex-direction: column;
  width: 30%; */
}
.time-box-main {
  width: 20%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* display: flex;
  align-items: center;
  margin-bottom: 10px; */
}
.hr-box .time-box {
  background: #d6f7fb;
}
.min-box .time-box {
  background: #fbe3e4;
}
.second-box .time-box {
  background: #ffefe1;
}
.phase-main {
  width: 40%;
  /* background-color: yellow; */
  height: 300px;
}
.progress-main {
  width: 60%;
  /* background-color: lime; */
  height: 300px;
}
/* .phase-progress-main{
  display: flex;
  margin-top: 30px;
} */
.presale-progress-main {
  display: flex;
  justify-content: center;
}
/* .progress-img{
  width: 120px;
} */
.presale-progress-main {
  text-align: center;
  margin: 15px 0 10px 0;
}
.presale_token_phase_main {
  justify-content: center !important;
}
.token-phase-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cus-data-grid .MuiDataGrid-menuIconButton,
.cus-data-grid .MuiDataGrid-iconSeparator {
  display: none;
}
.cus-data-grid .MuiSvgIcon-root {
  color: rgb(118, 118, 118);
  width: 20px;
  height: 20px;
}
.cus-data-grid .MuiDataGrid-columnHeaderTitle {
  color: #726f7c;
  font-family: "Poppins-Medium";
  text-transform: uppercase;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #32b0c4 !important;
}
.CircularProgressbar .CircularProgressbar-text {
  fill: #5b5865 !important;
  font-family: "Poppins-Medium";
  font-size: 12px;
  position: absolute;
  transform: translate(0px, -19px);
}
.prog-wrapper {
  display: flex;
  justify-content: center;
  margin: 20px 0 13px 0;
}
