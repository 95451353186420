.swap-box-main {
  padding: 10px;
}

.swap-box-content {
  align-items: center;
  background-color: #fff;
  border: 1px solid #2cb4c921;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px;
}

.input-box .form-lable {
  color: #000;
  font-family: Poppins-Medium;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.swap-input-box .form-group .form-control input {
  background-color: initial;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: #353945;
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 18px;
  padding: 11px 16px;
}

.form-group .form-control input::placeholder {
  color: #08080a;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.excha-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 21px;
}

.swap-box-wrapper {
  position: relative;
}

.full-width .input-box.swap-input-box {
  max-width: 100%;
}
.full-width.swap-box-content {
  flex-wrap: wrap;
}
.upload-wrape {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.upload-wrape .upload-sec {
  width: 100%;
  width: calc((100% / 2) - 10px);
}
.upload-wrape .upload-pad {
  border: 1px solid #2cb4c921;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  min-height: 150px;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}
.upload-wrape .upload-pad input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  opacity: 0;
}
.upload-wrape .upload-pad img {
  max-width: 100%;
  /* max-height: 100%; */
  max-height: 129px;
  /* max-width: 193px;
object-fit: cover; */
}
.upload-wrape .upload-pad .browse {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}
.upload-wrape .upload-pad .browse span {
  color: #ffffff;
  font-size: 13px;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.upload-wrape .upload-pad .browse img {
  width: 30px;
  fill: white;
}
.input-box.swap-input-box.full-width p.form-lable {
  min-height: 23px;
}
.input-box.swap-input-box.full-width p.form-lable span {
  padding: 0;
}

.upload-wrape .upload-pad:hover {
  cursor: pointer;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}
.upload-wrape .upload-pad:hover input {
  cursor: pointer;
}

.upload-wrape .upload-pad.attached .browse {
  bottom: -100%;
  transition: all 0.25s linear 0.25s;
}
.upload-wrape .upload-pad.attached .browse {
  bottom: 0;
  background: rgba(0, 0, 0, 0.45);
  transition: all 0.25s linear 0.25s;
}
.right-align {
  text-align: right;
}
/* .upload-wrape .upload-pad:hover .browse {bottom: 0; transition: all 0.15s linear 0.15s;cursor: pointer;} */
/* extra css */
.mob-header {
  display: none;
}
.admin-sidebar-logo {
  max-width: 80px;
  height: 55px;
  width: 100%;
  object-fit: contain;
  padding: 4px 0;
  margin-top: 4px;
}

.admin-sidebar-logo-main {
  text-align: center;
}
.profile-main {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-main img {
  width: 50px;
  object-fit: cover;
}

.main-title-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-title-sec .admin-page-title {
  color: #111827;
  font-size: 26px;
  line-height: 39px;
  font-family: "Poppins-SemiBold";
  margin-bottom: 10px;
}
.excha-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 21px;
}
/* .display-row > * {
  padding: 0px 8px;
} */

.display-row {
  margin-left: -8px;
  margin-right: -8px;
}
.swap-box-wrapper {
  position: relative;
}
.swap-box-wrapper .swap-input-box .MuiSelect-select {
  padding: 10px 32px 10px 10px;
}
.swap-box-content {
  align-items: center;
  background-color: #fff;
  border: 1px solid #2cb4c921;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px;
}
.full-width.swap-box-content {
  flex-wrap: wrap;
}
.swap-box-wrapper {
  position: relative;
}
.full-width .input-box.swap-input-box {
  max-width: 100%;
}
.full-width.swap-box-content {
  flex-wrap: wrap;
}
.input-box.swap-input-box.full-width p.form-lable {
  min-height: 23px;
}
.input-box.swap-input-box.full-width p.form-lable span {
  padding: 0;
}
.input-box .form-lable {
  color: #000;
  font-family: Poppins-Medium;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}
.swap-input-box .form-group .form-control input {
  background-color: initial;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: #353945;
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 18px;
  padding: 11px 16px;
}
.input-box {
  margin-bottom: 15px;
}
.swap-input-box .form-group .form-control input {
  border-bottom: 0px solid #35394533 !important;
}
.swap-input-box .MuiInputBase-root {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d1d5db !important;
}
.swap-input-box {
  max-width: 48%;
  width: 100%;
}
.form-group .form-control input::placeholder {
  color: #08080a;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}
.edit_profile_field .form-group .form-control fieldset {
  border: 0px;
  border-width: 0px;
  border-color: transparent;
}
.form-group .form-control input,
.form-control-details-auth .icon-position-rel input {
  background-color: initial;
  border: 1px solid #d1d5db !important;
  border-radius: 14px !important;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
  /* padding: 11.45px 16px !important; */
  width: 100%;
}

.edit_profile_field .dropdown-box .form-control {
  width: 100%;
  position: relative;
}
.edit_profile_field .dropdown-box .form-control .dropdown-select {
  border: 1px solid #171d35 !important;
  border-radius: 8px;
}
.form-control-details-auth .custom-auth-user-control {
  width: 100%;
}

.copy-hash-id-flex .form-control-details-auth {
  width: 100%;
}
.form-control-new fieldset {
  border: 1px solid #d1d5db;
  border-radius: 2px;
}
.crypto-token-form .withdraw-main-btn {
  text-align: center;
  margin: 30px 0;
}
.dropdown-box .MuiMenu-list .drop-menuitem {
  color: #353945;
  font-size: 12px;
  line-height: 20px;
  font-family: "Poppins-Regular";
}
.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  background-image: linear-gradient(72.47deg, #3fe398 22.16%, #32b0c4 76.47%);

  border-radius: 4px;
  width: 100%;
  /* max-width: 380px; */
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  padding: 10px 15px;
  text-transform: capitalize;
}
.btn-main-primary .withdraw-btn {
  max-width: 200px;
}
.close-id-update {
  position: absolute;
  right: 0;
  top: 0;
}
.close-id-update img {
  width: 11px;
  height: 11px;
}
.input-id-size .form-group .MuiInputBase-colorPrimary .MuiInputBase-input {
  padding: 2.5px 15px !important;
  border-radius: 7px !important;
  border: none !important;
}
.kyc-error-message {
  color: red;
  font-size: 12px;
  font-family: "Poppins-Regular";
  margin-top: 5px;
}

.upload-sec .upload-pad {
}
