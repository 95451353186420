.admin-sidebar-main {
  height: 100vh;
  left: 0;
  overflow: auto;
  /* padding: 0px 16px; */
  position: fixed;
  top: 0;
  width: 290px;
  z-index: 999;
  /* border-right: 1px solid #e5e7ebab; */
  box-shadow: 0 2px 6px rgba(47, 43, 61, 0.14), 0 0 transparent, 0 0 transparent;
  outline: none;
  background: #ffffff;
  transition: 0.5s all;
}

.admin-sidebar-main.active {
  left: -300px;
}

.admin-sidebar-main {
  height: 100%;
  left: 0px;
}

.scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
.scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1c4;
}

/* Handle */
.scrollbar::-webkit-scrollbar-thumb {
  background: #d7cfcf40;
}

/* Handle on hover */
/* .scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.redirect-home-link {
  text-decoration: none;
}

.admin-sidebar-logo-main {
  text-align: center;
}

.admin-side-logo-text {
  /* text-align: left; */
  display: flex;
  align-items: center;
  justify-content: center !important;
  background: #ffffff;
  /* padding: 20px; */
  padding: 5px;
  border-bottom: 2px solid rgb(238 237 240);
}

.admin-side-logo-text .admin-sidebar-logo,
.admin-side-logo-text .logo-text {
  display: block;
}

.admin-sidebar-logo {
  max-width: 80px;
  /* height: 55px; */
  width: 100%;
  object-fit: contain;
  padding: 4px 0;
  margin-top: 4px;
}

.admin-side-logo-text .admin-sidebar-logo {
  /* height: 40px; */
  /* max-width: 190px; */
  max-width: 154px;
  /* max-width: 64px; */
  padding: 0;
  margin-top: 0;
}

.logo-text {
  color: rgba(47, 43, 61, 0.78);
  font-family: "Poppins-Bold";
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 27px;
}

.admin-sidebar-list-main .admin-sidebar-list {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
  padding: 0px;
  margin-bottom: 10px;
}

.admin-sidebar-list-main .admin-sidebar-link {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  color: rgba(47, 43, 61, 0.68);
  display: flex;
  padding: 10px 12px;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
  color: #fff;
  /* border-radius: 10px; */
  /* background: #5a320a; */
  /* background:#FFFFFF33; */
  background-image: linear-gradient(
    72.47deg,
    rgb(63 227 152) 22.16%,
    rgb(50 176 196) 76.47%
  );
  /* background-image: linear-gradient(72.47deg, rgb(115, 103, 240) 22.16%, rgba(115, 103, 240, 0.7) 76.47%); */
  border-radius: 6px;
  /* box-shadow: rgba(115, 103, 240, 0.48) 0px 2px 6px 0px; */
}

.admin-sidebar-list-main .admin-sidebar-link.active svg path {
  fill: #000000;
}

.admin-sidebar-list-main {
  padding: 16px 16px;
  min-height: 100vh;
  background: #ffffff;
  /* background: transparent linear-gradient(180deg, #089CBC 0%, #52EA9C 100%) 0% 0% no-repeat padding-box; */
}

.admin-sidebar-list-main ul li:hover a.dashboard-header {
  /* background: #5a320a; */
  background: #f5f5f6;
}

.admin-sidebar-list .MuiAccordion-root {
  background-color: transparent;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  color: #ffffff;
  box-shadow: none;
}

.admin-sidebar-list .MuiAccordionSummary-expandIconWrapper svg {
  color: #fff;
}

.admin-sidebar-list .MuiAccordionDetails-root {
  padding: 0px 16px 0px !important;
}

.admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.admin-sidebar-submenulist
  .admin-sidebar-listitem:first-child
  .admin-sidebar-link {
  margin-top: 20px;
}

.admin-submenu-listitem-main .admin-sidebar-link {
  display: block;
}

.admin-sidebar-submenulist .admin-sidebar-link {
  margin-left: 10px;
}

.align-center {
  display: flex;
  align-items: center;
}

a.admin-sidebar-link .align-center span {
  margin-left: 8px;
}

.dashboard-header {
  font-size: 15px;
  line-height: 22px;
  font-family: "Poppins-Regular";
  color: rgba(47, 43, 61, 0.68);
  display: flex;
  padding: 10px 12px;
  /* margin-bottom: 10px; */
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.admin-sidebar-icons svg {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.admin-sidebar-icons {
  margin-right: 8px;
  display: flex;
  width: 24px;
  object-fit: contain;
  /* filter: brightness(10); */
}

.admin-sidebar-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  /* filter: brightness(10); */
}
.user-sidebar-icon {
  height: 37px;
  width: 37px;
  animation: rotate-animation 3s infinite linear;
}

/* .admin-sidebar-list-main .admin-sidebar-link.active .admin-sidebar-icon{
filter: unset;
  } */
.admin-header-main {
  background: #ffffff;
  /* background: #27170a; */
  position: fixed;
  top: 20px;
  /* left: 290px; */
  left: 310px;
  right: 20px;
  border-radius: 6px;
  z-index: 9;
  padding: 13.5px 20px 13.5px 20px;
  /* border-bottom: 1px solid #BBE8F2; */
  transition: 0.5s all;
  /* max-width: 1036px; */
  box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .dashboard-main {
    display: flex;
    width: 100%;
} */
.dashboard-main.active .admin-header-main {
  left: 20px;
  max-width: calc(100% - 40px);
}

.res-admin-header .admin-header-row {
  justify-content: space-between;
}

.res-admin-header .admin-header-row button {
  padding: 0;
  display: flex;
  justify-content: start;
}

.res-admin-logo {
  display: none;
}

.admin-header-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flex-drop-main {
  display: flex;
  align-items: center;
}

.flex-drop-main .admin-header-drop {
  font-size: 14px;
  font-family: "Poppins-Bold";
  line-height: 21px;
  color: rgba(47, 43, 61, 0.78);
  text-align: start;
}

.flex-drop-main .header-user-name {
  text-transform: none !important;
}
.flex-drop-main .admin-drop-user {
  font-size: 14px;
  font-family: "Poppins-Bold";
  line-height: 21px;
  color: #6b7280;
  text-align: start;
}

.admin-header-profile-icon {
  height: 36px;
  width: 36px;
  margin-right: 12px;
  object-fit: cover;
  border-radius: 50%;
}

.drop-header-menu .MuiPaper-rounded {
  box-shadow: 10px 10px 20px #0000001a;
  border: 1px solid #e5e7eb;
  margin-top: 13px;
  border-radius: 1px;
}

.drop-header-menu .drop-header-menuitem {
  font-size: 12px;
  line-height: 14px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  border-bottom: 1px solid #e5e7eb;
  padding: 11px 16px;
  text-decoration: none;
}

.drop-header-menu ul {
  padding: 0px;
}

.text-decoration {
  text-decoration: none;
}

.drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
}

.cross-res {
  display: none;
}

.airdrop-section-main .dashboard-left-main {
  display: none;
}

.dashboard-main.active .dashboard-left-main {
  flex: 0;
}

.after-blur-header-effect {
  position: relative;
}

.after-blur-header-effect::after {
  position: fixed;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: linear-gradient(
    180deg,
    rgba(248, 247, 250, 70%) 44%,
    rgba(248, 247, 250, 43%) 73%,
    rgba(248, 247, 250, 0%)
  );
  background-repeat: repeat;
  block-size: calc(64px + 1.5rem);
  content: "";
  inset-block-start: -1rem;
  inset-inline-end: 0;
  inset-inline-start: 0;
  -webkit-mask: linear-gradient(black, black 18%, transparent 100%);
  mask: linear-gradient(black, black 18%, transparent 100%);
  left: 0;
  right: 0;
  width: 100%;
  display: block;
  height: 86px;
  top: 0;
  z-index: 2;
}
.admin-sidebar-link.active .admin-sidebar-icon {
  /* filter: brightness(10); */
}
.mobile-menu-btn:hover {
  background: transparent;
}
.user-sidebar-loading-icon {
  height: 100px;
  width: 100px;
  animation: rotate-animation 2s infinite linear;
}