@media screen and (max-width: 1365px) {
  .join-wait-input input {
    /* min-width: 600px !important; */
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .join-wait-input input {
    /* min-width: 480px !important; */
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .res-join-wait-input input {
    /* min-width: 300px !important; */
  }
}
@media screen and (max-width: 767px) {
  .join-wait-input input {
    min-width: 180px !important;
  }
  /* .input-box-admin2 .form-group {
        margin-bottom: 0px !important;
    } */
  .res-btn-main {
    margin-top: 0px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 374px) {
  .join-wait-input input {
    min-width: 130px !important;
  }
}
