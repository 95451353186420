@media screen and (max-width: 1365px) {}

@media screen and (min-width: 992px) and (max-width:1199px) {}

@media screen and (max-width:991px) {
    .transaction-right {
        flex-direction: column;
        width: 100%;
    }
    .transaction-right .admin-text-field-main {
        margin-right: 0px;
    }
}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (max-width:767px) {}

@media screen and (min-width: 320px) and (max-width:374px) {}

@media only screen and (max-width: 320px) {}