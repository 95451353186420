/* .waitlist-request-table tr th:first-child,
.waitlist-request-table tr td:first-child {
  width: 20% !important;
} */
.waitlist-request-table tr th:nth-child(1),
.waitlist-request-table tr td:nth-child(1) {
  width: 20% !important;
}

.waitlist-request-table tr th:nth-child(2),
.waitlist-request-table tr td:nth-child(2) {
  width: 10% !important;
}

.waitlist-request-table tr th:nth-child(3),
.waitlist-request-table tr td:nth-child(3) {
  width: 10% !important;
}

.waitlist-request-table tr th:nth-child(4),
.waitlist-request-table tr td:nth-child(4) {
  width: 10% !important;
}

.waitlist-request-table tr th:nth-child(5),
.waitlist-request-table tr td:nth-child(5) {
  width: 10% !important;
}

.waitlist-request-table tr th:nth-child(6),
.waitlist-request-table tr td:nth-child(6) {
  width: 10% !important;
}

.waitlist-request-table tr th:nth-child(7),
.waitlist-request-table tr td:nth-child(7) {
  width: 10% !important;
}

.waitlist-request-table tr th:nth-child(8),
.waitlist-request-table tr td:nth-child(8) {
  width: 10% !important;
}

.waitlist-request-table tr th:nth-child(9),
.waitlist-request-table tr td:nth-child(9) {
  width: 10% !important;
}
.waitlist-drop .MuiInputBase-colorPrimary {
  width: 209px;
}
.waitlist-drop .MuiOutlinedInput-input {
  padding: 8px 15px;
  border: 1px solid #d1d5db;
}
