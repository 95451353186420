.dash-referral-btn-main {
  margin-top: 20px;
  margin-bottom: 21px;
}

.text-copy-box {
  padding: 11px 15px 12px;
  background: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  /* max-width: 588px; */
  max-width: 600px;
  width: 100%;
}

.dash-text-copy-box {
  margin-right: 10px;
}

.modal-text-copybox {
  max-width: 100%;
  width: 82% !important;
}

.qr-main-modal {
  width: 500px !important;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #d1d5db !important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15) !important;
  padding: 50px 32px !important;
}

.comming-soon-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .set-modal-line {
    position: relative;
}

.set-modal-line:after {
    content: "";
    background: #7f7f7f4a;
    top: 0px;
    right: -28px;
    width: 2px;
    height: 100%;
    display: flex;
    position: absolute;
} */

.qr-modal-set .MuiBox-root {
  position: unset !important;
  top: 50%;
  left: 50%;
  transform: initial !important;
  max-width: 350px !important;
  padding: 6px 20px;
  background: white;
  margin: auto;
}

.qrcode-title {
  color: #111827 !important;
  font-size: 23px !important;
  line-height: 30px !important;
  font-family: "Poppins-SemiBold" !important;
  text-align: center !important;
  margin-bottom: 20px !important;
}

.modal-text {
  font-size: 14px !important;
  font-family: "Poppins-Medium" !important;
  color: black !important;
  line-height: 20px !important;
}
