.presale-table-main .table .table-th:nth-child(1),
.presale-table-main .table .table-td:nth-child(1) {
width: 10% !important;
}

.presale-table-main .table .table-th:nth-child(2),
.presale-table-main .table .table-td:nth-child(2) {
width: 17% !important;
}

.presale-table-main .table .table-th:nth-child(3),
.presale-table-main .table .table-td:nth-child(3) {
width: 17% !important;
}

.presale-table-main .table .table-th:nth-child(4),
.presale-table-main .table .table-td:nth-child(4) {
width: 15% !important;
}

.presale-table-main .table .table-th:nth-child(5),
.presale-table-main .table .table-td:nth-child(5) {
width: 15% !important;
}