.form-box {
    background: #fff;
    border-radius: 6px;
}

.res-set-modal-hit {
    padding: 15px 25px !important;
    border-radius: 14px;
}

.res-set-modal-hit h4 {
    color: #5b5865;
    font-family: "Poppins", Arial, sans-serif;
    font-weight: 900;
    font-size: 28px;
    letter-spacing: -0.03em;
    line-height: 31.4px;
    text-align: center;
    margin: 10px 0px 30px !important;
}

.join-hit li {
    padding: 5px 0 !important;
}

/* .country-code-main .country-code-details .MuiInput-underline {
    min-height: 41px;
} */

.checkbox-lable .MuiFormControlLabel-label {
    text-align: left;
}

.set-grid-modal {
    padding-top: 5px !important;
}