@media screen and (max-width: 1365px) {
  .page-table-main .table-container {
    max-width: calc(100vw - 100px);
    overflow: auto;
  }

  .page-table-main .table {
    min-width: 1200px;
  }
  .my_income-table-main .table{
    min-width: 900px;
    }
    .transaction-table-main .table{
      min-width: 900px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .user-head-title {
    align-items: start;
    flex-direction: column;
  }

  .dashboard-content .admin-page-title {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 991px) {
  .admin-header-profile-box {
    display: block;
  }
  .res-none {
    display: none;
  }
  .login-bg-content {
    display: none !important;
  }

  .preasale-search {
    width: 100%;
    flex-direction: column;
  }

  .cus-search .user-search-box {
    margin: 10px 0;
  }

  .add-user-btn {
    margin-left: auto;
  }

  .presale-input-mr-15 {
    margin-right: 0px;
  }
  .user-detail-profile {
    width: 80px;
    height: 80px;
  }
  .tabpanelinner-body {
    padding: 0;
  }
  .input-box .ck-content {
    min-height: 220px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
   .basic-tabcard-main{
  display: flex;
  flex-wrap: wrap;
  height: inherit;
  /* flex-direction: column; */
  align-items: flex-start;
  gap: 10px;
}
  .income-card {
    min-width: inherit;
}
  .admin-sidebar-main {
    padding: 0px 10px;
    width: 230px;
  }

  .dashboard-left-main {
    flex: 0 0 230px;
    max-width: 230px;
  }

  .admin-sidebar-icons {
    margin-right: 10px;
  }

  .dashboard-header {
    padding: 10px 10px 10px;
  }

  /* .dashboard-containt-main {
        padding: 88px 15px 24px 15px;
    } */
  .admin-header-main {
    left: 240px;
    right: 10px;
  }

  .card-col-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .admin-sidebar-list-main {
    padding: 0;
  }

  .dashboard-containt-main {
    padding: 108px 10px 24px 10px;
  }

  .admin-dashboad-row {
    margin-bottom: 10px;
  }

  .user-head-title {
    align-items: start;
    flex-direction: column;
  }

  .dashboard-content .admin-page-title {
    margin-bottom: 10px;
  }
  .page-table-main .table {
    min-width: 750px;
  }
  .token-card {
    margin-bottom: 16px;
  }
  
}

@media screen and (max-width: 767px) {
  .user-details table tr td{
white-space: nowrap;
  }
  .stack-box-btn {
    width: 100%;
  }

  .terms-and-condition-modal {
    width: 360px !important;
    border: none !important;
  }
  /* .close-icon-modal {
    filter: invert(1);
    width: 15px;
  } */

  .res-admin-header .logo-text {
    font-size: 20px;
  }

  .admin-sidebar-main {
    height: 100%;
    left: -290px;
  }

  .admin-sidebar-main.active {
    left: 0;
  }

  .admin-sidebar-main.active {
    left: 0;
  }

  .res-admin-header .logo-text {
    font-size: 18px;
  }

  .res-admin-header + .dashboard-containt-main {
    padding-top: 88px;
  }

  .dashboard-left-main {
    flex: unset;
    max-width: unset;
    width: unset;
  }

  .admin-sidebar-logo-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .admin-side-logo-text .admin-sidebar-logo,
  .admin-side-logo-text .logo-text {
    display: none;
  }

  .admin-side-logo-text .admin-sidebar-logo {
    max-width: 55px;
    height: 55px;
  }

  .admin-side-logo-text .admin-sidebar-logo,
  .admin-side-logo-text .logo-text {
    display: none;
  }

  .cross-res {
    justify-content: flex-end;
    display: flex;
    margin-top: 10px;
    position: absolute;
    top: 4px;
    right: 0;
  }

  .cross-res button {
    padding: 0;
  }

  .res-admin-header {
    display: flex;
    justify-content: space-between;
  }

  .admin-header-main {
    left: 10px;
    right: 10px;
  }

  .res-admin-logo .admin-sidebar-logo {
    height: 37px;
    width: 55px;
    margin-top: 0px;
    max-width: 85px;
    padding: 0;
    display: block;
  }

  .res-admin-logo {
    justify-content: left;
  }

  .res-admin-header {
    display: flex;
    justify-content: space-between;
    z-index: 99;
  }

  .res-admin-header .admin-header-row button {
    justify-content: center;
  }

  .dashboard-containt-main {
    padding: 100px 10px 20px 10px;
  }

  .change-pass-main {
    padding: 0;
  }

  .dashboard-content .admin-page-title {
    margin-bottom: 0px;
  }

  .preasale-search {
    flex-direction: column;
  }
  .flex-input {
    margin-bottom: 10px;
  }
  .admin-header-main {
    padding: 13.5px 10px 13.5px 10px;
  }

  /* .preasale-search .btn-main{
margin-top: 10px;
    } */
  /* .add-user-input {
        margin-bottom: 0px;
    } */
  .add-user-input {
    margin-bottom: 15px;
  }
  .user-btn-flex {
    margin-top: 20px;
  }
  .page-table-main .table-container {
    max-width: calc(100vw - 20px);
  }
  .preasale-search .MuiFormControl-root {
    min-width: 100% !important;
  }
  .icon-setclose {
    display: block;
  }
  .res-reff-link {
    display: block;
  }
  .res-reff-link .group-btn .export-btn-main {
    margin-right: 0;
  }
  /* .res-reff-link .group-btn {
    display: block;
  } */
  .res-reff-link .primary-border-btn-main .primary-border-btn {
    font-size: 14px;
    padding: 7px 13px;
  }
  .checkbox-main .checkbox-lable {
    display: block;
    text-align: left;
  }
  .basic-tabcard-main{
    display: flex;
    flex-wrap: wrap;
    height: inherit;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .reason-modal-open .rejection-modal{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: white;
    border: 2px solid black;
    border-radius: 20px;
    box-shadow: 24px;
    padding: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .bg-white {
    padding: 10px;
  }
}

@media only screen and (max-width: 320px) {
}
