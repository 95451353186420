.login-bg-content {
  /* background-image: url("../../../../assets/images/png/bgFbcLogo.png"); */
  background: linear-gradient(0deg, #a9f7ff, #c8ffe4);
  /* background: transparent linear-gradient(180deg, #089CBC 0%, #52EA9C 100%); */
  /* background: #f8f7fa; */
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.flex-check-login {
  margin-top: -10px;
  margin-bottom: 30px;
}

.create-account-text .signup-redirect {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  text-decoration: none;
  color: #32b0c4;
}
.admin-login-main-wrap {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.admin-login-inner .admin-wel-text {
  color: #5d5a68;
  font-size: 24px;
  line-height: 35px;
  font-family: "Poppins-Bold";
  margin-bottom: 4px;
}
.admin-login-inner .admin-sign-para {
  margin-bottom: 24px;
  color: rgba(47, 43, 61, 0.68);
}
.bg-login-vector {
  max-width: 700px;
  /* height: 100vh;s */
}
