* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

body,
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/poppins-bold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Teko-Regular";
  src: url("../fonts/Teko/Teko-Regular.ttf");
}

@font-face {
  font-family: "Teko-Bold";
  src: url("../fonts/Teko/Teko-Bold.ttf");
}

@font-face {
  font-family: "Teko-Medium";
  src: url("../fonts/Teko/Teko-Medium.ttf");
}

@font-face {
  font-family: "Teko-SemiBold";
  src: url("../fonts/Teko/Teko-SemiBold.ttf");
}
.dashboard-main {
  display: flex;
  width: 100%;
}
.dashboard-left-main {
  flex: 0 0 290px;
  max-width: 290px;
  width: 100%;
  transition: 0.5s all;
}
.dashboard-right-main {
  flex: 1 1;
  width: calc(100vw - 290px);
}
.display-row {
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.display-row > * {
  padding: 5px 7.5px;
}
.grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
}
.dashboard-containt-main {
  background-color: #f4f3f6;
  min-height: 100vh;
  padding: 108px 20px 20px 20px;
}

.dashboard-containt-main {
  background-image: url(../images/png/bgFbcLogo1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.dashboard-content .admin-page-title {
  color: #5d5a68;
  font-size: 22px;
  line-height: 35px;
  font-family: "Poppins-SemiBold";
  /* margin-bottom: 20px; */
}
.user-head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  /* margin-top: 10px; */
}
.admin-dash-main .admin-page-title {
  color: #004554;
  font-size: 16px;
  line-height: 25px;
  font-family: "Poppins-SemiBold";
}
.mr-15 {
  margin-right: 15px;
}
.mt-0 {
  margin-top: 0 !important;
}
.border-btn-main .border-btn,
.border-btn-main .border-btn:hover {
  /* border: 1px solid #1acb41; */
  border-radius: 6px;
  background-color: #e8e8ea;
  padding: 6px 14px 5px;
  font-size: 15px;
  line-height: 18px;
  color: #6b6874;
  font-family: "Poppins-Medium";
  text-transform: capitalize;
}
/* Date Range picker */

.date-picker-mui {
  border: 1px solid #d1d5db !important;
  border-radius: 6px !important;
}
.date-range-picker .date-picker-mui .MuiStack-root {
  padding-top: 3px;
}
.input-box-admin2 {
  margin-bottom: 0px !important;
}
.input-box-admin2 .form-group {
  margin-bottom: 0px !important;
}
.input-box .MuiInputBase-root {
  width: 100%;
}

.date-range-picker .MuiInputBase-colorPrimary input {
  padding: 6.5px 14px;
  font-size: 13px;
  font-family: "Poppins-Medium";
  height: 23px;
}
fieldset {
  border-color: transparent !important;
  top: 0 !important;
}
.primary-btn-main-comman .btn-dark-comman-main {
  /* color: #fff;
  text-transform: capitalize;
  border: 1px solid #ffffff7d;
  background: linear-gradient(90deg, #1acb41 0%, #7ed947 52%);
  background-size: 894px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
  border: 1px solid #ffffff7d; */
  min-width: 95px;
  /* margin-right: 10px; */
  background: linear-gradient(90deg, #1acb41 0%, #7ed947 52%);
  color: #ffffff !important;
  min-height: 40px;
  padding: 6px 14px 5px;
  font-size: 12px;
  border: 1px solid #1acb41;
  line-height: 15px;
  border-radius: 4px;
  text-transform: capitalize;
  border: 1px solid;
  transition: all 0.5s ease;
}

/* .primary-btn-main-comman .btn-dark-comman-main:hover {
  background-position: 100% !important;
  color: #1acb41 !important;
  border-color: #1acb41 !important;
  background: transparent !important;
} */
/* .primary-btn-main-comman .btn-dark-comman-main {
  background-image: linear-gradient(30deg,#1acb41 50%,transparent 0)!important;
  background-position: 0!important;
  background-repeat: no-repeat!important;
  background-size: 894px!important;
  background-size: 772px!important;
  border: 1px solid #1acb41;
  color: #fff;
  text-transform: capitalize;
  transition: background .3s ease-in-out!important;
} */

/* table */
.page-table-main .table-container {
  padding-bottom: 7px;
  box-shadow: none;
  /* border-radius: 14px; */
}
.preasale-search {
  display: flex;
}
.cus-search .user-search-box {
  position: relative;
  margin-right: 15px;
}
.cus-search input {
  padding-left: 35px !important;
}
.form-group .form-control input {
  /* border: 1px solid #74540b42; */
  border: 1px solid #c9c8cd;
  width: 100%;
  padding: 0px 15px;
  /* padding: 8.3px 15px; */
  background-color: transparent;
  color: #6b7280;
  font-size: 15px;
  border-radius: 6px;
  line-height: 23px;
  min-height: 38px;
  max-height: 38px;
  font-family: "Poppins-Regular";
}
.user-search-box .form-group .form-control input {
  /* padding-top: 9px;
  border-radius: 5px;
  padding-bottom: 10px; */
  line-height: 22px;
}
.form-control fieldset {
  border: transparent !important;
  border-radius: 2px;
}
.cus-search .user-search-box .search-grey-img {
  /* position: absolute;
  top: 16px;
  left: 16px; */
  position: absolute;
  top: 50%;
  left: 16px;
  width: 14px;
  transform: translateY(-50%);
}
.primary-btn-main-comman {
  cursor: pointer;
}
.add-user-btn {
  display: flex;
  align-items: center;
  background-color: #74540b;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  max-width: 100px;
  width: 100%;
  justify-content: center;
  min-width: 115px;
  font-family: "Poppins-Medium";
}
.add-user-btn svg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  object-fit: contain;
}
.table-design-main table {
  margin: 0 !important;
}
.table-design-main table {
  /* border-collapse: separate; */
  border-collapse: collapse;
  border-spacing: 0 9px;
}
.page-table-main tr th,
.page-table-main tr td {
  text-align: left !important;
}
.kyc-table-list tr th,
.kyc-table-list tr td {
  text-align: left !important;
}
.table-design-main thead {
  /* background: #8b4b0b; */
  /* background: transparent linear-gradient(90deg, #1acb41 0%, #7ed947 100%) 0% 0%;  color: #fff !important; */
}
.table-design-main tr th {
  color: #726f7b !important;
  /* background: #8b4b0b; */
  /* background: transparent linear-gradient(90deg, #1acb41 0%, #7ed947 100%) 0% 0%;  color: #fff !important; */
}
.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: #111827;
  font-family: "Poppins-Medium";
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #d1d5db;
}
.table-design-main .table-th {
  padding: 17px 10px 17px 0px !important;
  font-family: "Poppins-SemiBold" !important;
  border-bottom: 1 !important;
}
.page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: #111827;
  font-family: "Poppins-Medium";
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #dedde0 !important;
}
.table-design-main tr td:first-child,
.table-design-main tr th:first-child {
  border-radius: 0px 0 0 0px;
}
.table-design-main .table-th:first-child {
  padding-left: 15px !important;
}

.table-design-main tr td {
  /* background: #2828281a; */
  /* border-bottom: 0 !important; */
}
.page-table-main .table-td {
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  padding: 10px 10px 10px 0px;
  /* border-bottom: 1px solid #d1d5db; */
  border-bottom: none;
}
.page-table-main .table .table-th:first-child,
.page-table-main .table .table-td:first-child {
  width: 12%;
  padding-left: 15px;
}
.page-table-main .table .table-td:first-child {
  color: #111827;
}
.view-icon {
  width: 20px;
  height: 20px;
}
.page-table-main .table-td {
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  padding: 10px 5px 10px 0px;
  /* border-bottom: 1px solid #dedde0; */
  border-bottom: none;
}
.transaction-wrap .page-table-main .table-td {
  padding: 10.5px 0;
}
.table-design-main tr td:last-child,
.table-design-main tr th:last-child {
  border-radius: 0 6px 6px 0;
}
.page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
  width: 14%;
}

.prasale-tot-transaction .table .table-th:nth-child(1) {
  width: 20%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
  width: 25%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(6),
.page-table-main .table .table-td:nth-child(6) {
  width: 18%;
}
.form-group .form-control input::placeholder {
  color: #6b7280;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}
.pagination-main .MuiTablePagination-actions .MuiIconButton-sizeMedium {
  padding: 0 !important;
}
.table-design-main .table-th {
  /* border-bottom: 1px !important; */
  font-family: "Poppins-Medium" !important;
  padding: 17px 10px 17px 0 !important;
}
.create-hover {
  align-items: center;
  display: flex;
}
.create-hover span.create-hover-span {
  display: none;
}
.page-table-main tr th:hover .create-hover span.create-hover-span {
  display: flex;
}
.page-table-main tr th:hover .create-hover span.create-hover-span {
  display: flex;
}
.page-table-main .table .table-td:nth-child(2) a {
  text-decoration: none;
  color: #6b7280;
}
.email-ellip {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}

.email-ellip a {
  text-decoration: none;
  color: black;
}
.waitlist-table tr td:last-child .MuiIconButton-sizeMedium:last-child label {
  margin-right: 0% !important;
}
.admin-tabs-main .admin-tab {
  font-size: 15px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  color: #666b84;
  text-transform: capitalize;
  padding: 10px 15px;
}
.admin-tabs-main .MuiTabs-indicator {
  /* background: transparent linear-gradient(90deg, #1acb41 0%, #7ed947 100%) 0% 0%; */
  background: #32b0c4;
  height: 2px;
}
.admin-tabs-main .admin-tab.Mui-selected {
  color: #32b0c4;
  font-family: "Poppins-Medium";
}
.admin-tabpanel > .MuiBox-root {
  padding: 24px 0px;
}
.tabpanelinner-body {
  padding: 15px;
  /* border: 1px solid #d1d5db; */
  border-radius: 6px;
}
.mt-25 {
  margin-top: 25px;
}
.primary-border-btn-main .primary-border-btn {
  border: 1.5px solid transparent;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding: 7px 15px;
  text-transform: capitalize;
  font-family: "Poppins-Medium";
  background: #32b0c4;
  cursor: pointer;
}

/* .primary-border-btn-main .primary-border-btn:hover{
  background-position: 100% !important;
    color: #1acb41 !important;
    border-color: #1acb41;
    background: transparent !important;
} */

.primary-border-btn-main-header .history-btn-header,
.primary-border-btn-main-header .history-btn-header {
  max-width: 87px;
  min-width: 82px;
  margin-left: 3px;
  font-family: "Poppins-Medium";
  height: 35px !important;
  min-width: 170px;
  margin-right: 15px;
  box-shadow: 0 2px 6px rgba(47, 43, 61, 0.14), 0 0 transparent, 0 0 transparent;
  border-radius: 6px;
}

.primary-btn-main-comman .deposit-btn-dark-comman-main,
.primary-btn-main-comman .deposit-btn-dark-comman-main {
  color: #fff;
  text-transform: capitalize;
  /* background: #74540b; */
  /* background: #5a320a !important; */
  /* background-image: linear-gradient(30deg, #5a320a 50%, transparent 50%) !important; */
  /* background-size: 772px!important; */
  /* background:   linear-gradient(82deg, #1acb41 0%, #7ed947 52%); */
  background: #32b0c4;
  /* background-size: 894px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important; */
  /* background-image: linear-gradient(30deg, #5a320a 50%, transparent 50%) !important; */
  /* background-size: 772px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
  border: 1px solid #1acb41; */
}

/* .primary-btn-main-comman .deposit-link-btn-dark-comman-main:hover {
  background-position: 100% !important;
  color: #1acb41 !important;
  border-color: #1acb41 !important;
  background: transparent !important;
} */

/* .primary-btn-main-comman .deposit-btn-dark-comman-main:hover {
  background-position: 100% !important;
  color: #1acb41 !important;
  border-color: #1acb41 ;
  background: transparent !important;
} */
.submit-btn-main .submit-user-btn,
.submit-btn-main .submit-user-btn:hover,
.comman-btn-main .primary-btn,
.comman-btn-main .primary-btn:hover {
  min-width: 95px;
  margin-right: 10px;
  /* background:   linear-gradient(90deg, #1acb41 0%, #7ed947 52%); */
  background: #32b0c4;
  color: #ffffff !important;
  min-height: 40px;
  padding: 6px 14px 5px;
  font-size: 15px;
  border: none;
  line-height: 18px;
  border-radius: 6px;
  text-transform: capitalize;
  font-family: "Poppins-Medium";
  /* border: 1px solid; */
}
.mb-10 {
  margin-bottom: 10px;
}
.input-box .form-lable {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 4px;
  margin-top: 0px;
  color: rgba(47, 43, 61, 0.78);
}

/* saloni Add (global.css) */

.display-flex {
  display: flex;
}

.referral-card-list-details .referral-box-ul,
.virtual-joyful-race-main .virtual-joyful-list,
.address-footer-main .footer-add-ul,
.footer-social-icon-main .footer-social-icon-ul {
  padding: 0px;
}

.admin-sub-title-main .admin-sub-title {
  color: #5d5a68;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-bold";
}

.input-design-div .main-select-box {
  width: 100%;
  border: 1px solid #74540b42;
  margin: 15px 0px;
  border-radius: 6px;
}

.input-design-div .MuiSelect-outlined {
  padding: 5.5px 14px;
}

.input-design-div em {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}

.setting-card {
  box-shadow: 0px 3px 6px #8888887a;
  padding: 15px;
  border-radius: 7px;
  min-height: calc(100vh - 120px);
}

.referral-list-tree-btn {
  display: flex !important;
  justify-content: space-between !important;
}

.flex-table {
  display: flex;
}

.ref-card-main {
  /* width: 50%; */
  width: 100%;
  border: 1px solid #e0e0e0;
  /* margin: 0 13px; */
}
.card-left-team {
  margin-right: 13px;
}

.ref-title {
  /* background: #7367f0; */
  color: #5b5865;
  padding: 10px;
  font-family: "Poppins-SemiBold";
  text-align: left;
}

.ref-inner {
  display: flex;
  flex-wrap: wrap;
  padding: 7px;
  overflow: auto;
  max-height: 300px;
}

.ref-card-inner {
  background: #f1eded;
  margin: 8px;
  padding: 5px 6px;
  min-width: 75px;
  border: 1px solid #d3cccc;
  border-radius: 4px;
  justify-content: center;
  display: flex;
  font-family: "Poppins-Regular";
  font-size: 14px;
  cursor: pointer;
}

.redirect-home-link {
  text-decoration: none;
  /* .border-btn-main .border-btn,
.border-btn-main .border-btn:hover {
  border: 1px solid #1acb41;
  border-radius: 4px;
  background-color: transparent;
  padding: 6px 14px 5px;
  font-size: 12px;
  line-height: 18px;
  color: #1acb41;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
} */
}
.discard-btn-main .discard-user-btn,
.discard-btn-main .discard-user-btn:hover {
  min-width: 83px;
  margin-right: 10px;
  min-height: 40px;
}
.me-0 {
  margin-right: 0 !important;
}
.file-upload-btn-main {
  position: relative;
  max-width: max-content;
}
.upload-profile-img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
}
.file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  box-shadow: 0px 3px 6px #00000029;
}
.add-user-data-main {
  /* padding: 25px; */
  /* border: 1px solid #d1d5db; */
  border-radius: 6px;
  /* box-shadow: 0px 3px 6px #0000001a; */
  /* box-shadow: 0px 0px 2px #4f46e5; */
}
.edit-profile-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.add-user-input {
  margin-bottom: 20px;
}
.add-user-input .form-control {
  width: 100%;
}
.add-user-input .form-control input {
  color: rgba(0, 0, 0, 0.6) !important;
}
.country-code-main .country-code-details {
  border: 1px solid #d1d5db;
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  color: #08080a;
  padding-left: 14px;
}

.country-code-main .country-code-details .MuiInput-underline:before {
  display: none !important;
}

.country-code-main .country-code-details .MuiInput-underline:after {
  border-bottom: 0px !important;
}

.country-code-main .country-code-details input {
  padding-left: 0px !important;
  font-size: 14px;
  line-height: 21px;
}

.country-code-add-user input {
  padding: 9.45px 16px !important;
}
.errorshow {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}
.user-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
}
.user-save-icon {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  object-fit: contain;
}
.change-pass-main {
  /* border: 1px solid #d1d5db; */
  border-radius: 6px;
  padding: 20px;
  max-width: 514px;
  width: 100%;
}
.admin-tabpanel .edit-highlight-text {
  color: #5b5867;
  font-family: "Poppins-Medium";
  margin-bottom: 4px;
  font-size: 15px !important;
}
.admin-tabpanel .edit-para-text {
  margin-bottom: 24px;
  color: #6b7280;
}
.input-mb-20px {
  margin-bottom: 20px;
}
.pass_group_main {
  position: relative;
}
.error-text {
  color: red;
  font-family: "Poppins-Regular";
  font-size: 13px;
  line-height: 15px;
  margin-top: 3px;
}
.pass_group_main .custom_form_control {
  padding-right: 0px;
  width: 100%;
}
.pass_group_main .custom_form_control input {
  padding-right: 45px !important;
}
.pass_group_main .pass_position {
  margin-left: 0px;
}
.pass_group_main .icon_end_icon {
  position: absolute;
  right: 16px;
}
.change-pass-flex {
  margin-bottom: 20px;
}
.change-pass-input-box .form-group .form-control input {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%;
  padding: 5.5px 15px;
  background-color: transparent;
}
/* .submit-btn-main .submit-user-btn:hover{
  color: #1acb41 !important;
} */
.primary-btn-main-comman .btn-dark-comman-main:hover,
.submit-btn-main .submit-user-btn:hover {
  color: #ffffff !important;
  /* border: 1px solid #1acb41; */
  background: #32b0c4;
}
.admin-login-box {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.admin-login-main {
  margin: auto;
  max-width: 380px;
  width: 100%;
}
.center-logo-login {
  text-align: center;
  margin-bottom: 20px;
}
.center-logo-login .logo-admin {
  height: 100px;
  object-fit: contain;
}
.card-login-main {
  background: #ffffff;
  /* padding: 30px 20px; */
}
/* .admin-login-inner .admin-wel-text {
  color: #1acb41;
  font-size: 24px;
  line-height: 25px;
  font-family: "Poppins-Bold";
  margin-bottom: 4px;
  text-align: center;
} */
/* .admin-login-inner .admin-sign-para {
  margin-bottom: 32px;
  color: #6b7280;
  text-align: center;
} */
.input-box-admin {
  margin-bottom: 24px;
}
.flex-all {
  display: flex;
  justify-content: space-between;
}
.checkbox-main {
  text-align: center;
  display: flex;
  align-items: center;
}
.checkbox-main .checkbox-lable {
  display: flex;
  align-items: flex-start;
}
.checkbox-main .checkbox-lable {
  color: #32b0c4;
  margin-left: 0px;
  margin-right: 0px;
}
.checkbox-main span {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
  color: #32b0c4;
}
.checkbox-lable .MuiCheckbox-root {
  padding: 0px;
  margin-right: 10px;
}
.checkbox-lable .terms-link {
  color: #32b0c4;
}
.checkbox-main .MuiSvgIcon-root {
  color: rgb(118, 118, 118);
  width: 20px;
  height: 20px;
}
.common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: "Poppins-Regular";
}
.admin-forgot-para {
  color: #004dd1;
}
.admin-forgot-para a {
  color: #32b0c4;
  text-decoration: none;
}
.btn-main .btn,
.btn-main .btn:hover {
  background: #32b0c4;
  /* background: transparent linear-gradient(90deg, #089CBC 0%, #52EA9C 100%) 0% 0% no-repeat padding-box; */
  border-radius: 0;
  /* box-shadow: none; */
  color: #fff !important;
  font-family: "Poppins-Regular";
  font-size: 15px;
  line-height: 21px;
  /* max-width: 380px; */
  min-height: 38px;
  padding: 5px 30px;
  text-transform: capitalize;
  width: 100%;
  border-radius: 6px;
}
.btn-main-submit {
  cursor: pointer;
}
.primary-btn-main-comman .btn-dark-comman-main {
  background: #32b0c4;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(47, 43, 61, 0.14), 0 0 transparent, 0 0 transparent;
  border: none;
  /* background: #5a320a!important; */
  /* background-image: linear-gradient(30deg,#5a320a 50%,transparent 0)!important; */
  /* background: transparent linear-gradient(90deg, #089CBC 0%, #52EA9C 100%) 0% 0% no-repeat padding-box; */
  /* background-position: 0!important;
  background-repeat: no-repeat!important;
  background-size: 894px!important;
  background-size: 772px!important;
  border: 1px solid #ffffff7d;
  color: #fff;
  text-transform: capitalize;
  transition: background .3s ease-in-out!important;
  border-radius: 5px; */
}
/* .primary-btn-main-comman .btn-dark-comman-main:hover {
  background-color: initial!important;
  background-position: 100%!important;
  border-color: #1acb41!important;
  color: #1acb41!important;
} */
.login-btn-main .login-btn {
  margin-top: 16px;
}
.box-shadow-none {
  box-shadow: none !important;
}
.transaction-right {
  display: flex;
}
.username-bold {
  font-weight: 600;
  color: black;
}
.transaction-right .admin-text-field-main {
  margin-right: 20px;
}
.transaction-table-main .table .table-th:nth-child(1),
.transaction-table-main .table .table-td:nth-child(1) {
  /* width: 16% !important; */
  width: 10% !important;
}

.transaction-table-main .table .table-th:nth-child(2),
.transaction-table-main .table .table-td:nth-child(2) {
  width: 10% !important;
}

.transaction-table-main .table .table-th:nth-child(3),
.transaction-table-main .table .table-td:nth-child(3) {
  width: 11% !important;
}

.transaction-table-main .table .table-th:nth-child(4),
.transaction-table-main .table .table-td:nth-child(4) {
  width: 15% !important;
}

.transaction-table-main .table .table-th:nth-child(5),
.transaction-table-main .table .table-td:nth-child(5) {
  width: 13% !important;
}

.transaction-table-main .table .table-th:nth-child(6),
.transaction-table-main .table .table-td:nth-child(6) {
  width: 10% !important;
}
/* Main Table */
.kyc-table-list .table .table-th:nth-child(1),
.kyc-table-list .table .table-td:nth-child(1) {
  width: 20%;
}

.kyc-table-list .table .table-th:nth-child(2),
.kyc-table-list .table .table-td:nth-child(2) {
  width: 20%;
}

.kyc-table-list .table .table-th:nth-child(3),
.kyc-table-list .table .table-td:nth-child(3) {
  width: 20%;
}

.kyc-table-list .table .table-th:nth-child(4),
.kyc-table-list .table .table-td:nth-child(4) {
  width: 15%;
}

.kyc-table-list .table .table-th:nth-child(5),
.kyc-table-list .table .table-td:nth-child(5) {
  width: 15%;
}
.kyc-table-list .table .table-th:nth-child(6),
.kyc-table-list .table .table-td:nth-child(6) {
  width: 10%;
}

/*  */

.login-details-title .login-title {
  /* font-size: 56px;
  font-family: "Teko-SemiBold";
  color: #1acb41;
  line-height: 90px; */
  color: #5d5a68;
  font-size: 24px;
  line-height: 35px;
  font-family: "Poppins-Bold";
  margin-bottom: 4px;
}

.f-direction {
  flex-direction: column;
}

.login-logo-details .logo-login {
  max-width: 300px;
  height: 300px;
}

.logo-anim {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 40px; */
}

.login-logo-title {
  color: rgba(47, 43, 61, 0.78);
  /* padding-top: 40px; */
  font-size: 110px;
  line-height: 166px;
  font-family: "Poppins-Bold";
  /* font-size: 28px;
  font-family: "Poppins-SemiBold"; */
  /* animation: crescendo 1.5s alternate infinite ease-in; */
}

.login-pd-main {
  padding: 0px;
}

.login-form-details .login-max-content {
  max-width: 350px;
  margin: auto;
}

.login-form-details .login-details-title {
  /* margin-bottom: 40px; */
  margin-bottom: 20px;
}

.login-details-title .login-desc {
  font-size: 14px;
  font-family: "Poppins-Regular";
  /* color: #08080a; */
  line-height: 21px;
  margin-bottom: 24px;
  color: rgba(47, 43, 61, 0.68);
}

.login-grid-inner .login-column {
  padding: 0px 7.5px;
}

.login-grid-inner {
  margin: 0px -7.5px;
}

.custom-group .form-label {
  /* font-size: 14px;
  color: #08080a;
  font-family: "Poppins-Medium";
  line-height: 20px;
  margin-bottom: 11px; */
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 4px;
  margin-top: 0px;
  color: rgba(47, 43, 61, 0.78);
}

.form-control p {
  margin-left: 0px !important;
}

.checkbox-main .custom-check span {
  border: 1px solid #6b6e74;
  border-radius: 4px;
}

.checkbox-main .custom-check {
  margin-right: 8px;
  padding: 0px;
}

.primary-btn-main-user .comman-primary-btn,
.primary-btn-main-user .comman-primary-btn:hover,
.primary-btn-main-user .comman-primary-btn.active,
.primary-btn-main-link-user .comman-primary-btn,
.primary-btn-main-link-user .comman-primary-btn:hover,
.primary-btn-main-link-user .comman-primary-btn.active {
  background: #32b0c4;
  border-radius: 6px;
  padding: 5px 30px;
  min-height: 38px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  text-transform: capitalize;
  box-shadow: none;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

.country-code-main .country-code-details svg {
  height: 17px;
}

.position-relative {
  position: relative;
}

.container {
  max-width: 1140px;
  padding-left: 15px;
  padding-right: 15px;
  margin: auto;
  width: 100%;
}

.primary-btn-main .btn-dark-comman,
.primary-btn-main .btn-light-comman,
.affliated-tree button {
  /* background: transparent !important;
  background-size: 751px !important;
  background-repeat: no-repeat !important;
  background-position: 0% !important;
  transition: background 300ms ease-in-out !important;
  cursor: pointer;
  color: #fff !important;
  border: 1px solid #fff !important */
  color: #fff;
  /* border: 1px solid #32b0c4!important; */
  background: #32b0c4;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.primary-btn-main .btn-dark-comman:hover,
.primary-btn-main .btn-light-comman:hover,
.affliated-tree button:hover {
  background-position: 100% !important;
  color: #ffffff !important;
  border-color: #32b0c4 !important;
  background: #32b0c4 !important;
}

.country-code-main .country-code-details .MuiPhoneNumber-flagButton {
  padding: 0px;
}
/* .page-bg {
  background-image: url(../images/png/bgimg.png);
} */
.user-title-row,
.withdrawal-head-title {
  margin-bottom: 0;
}
.submit-btn-main {
  display: flex;
  justify-content: flex-start;
}
.kyc-card-hgt {
  min-height: initial;
}

.kyc-row-main {
  margin: 0px -15px;
}

.kyc-input-details .dropdown-box .form-control {
  width: 100%;
  position: relative;
}

.kyc-input-details .dropdown-box .form-control .dropdown-select {
  border-radius: 4px;
  border: 1px solid #d1d5db;
  width: 100%;
  background-color: transparent;
}

.kyc-input-details .dropdown-select .MuiSelect-select {
  font-family: "Poppins-Regular";
  display: flex;
  padding: 7px 16px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}

.kyc-input-details .form-group .form-control fieldset {
  border: 0px;
  border-width: 0px;
  border-color: transparent;
}

.kyc-input-details .MuiMenu-list .menuitem {
  font-size: 14px;
}

.kyc-row-main .kyc-grid-column {
  padding: 0px 15px;
  margin-bottom: 15px;
}

.kyc-input-details .form-lable {
  font-size: 13px;
  line-height: 18px;
  font-family: "Poppins-Medium";
  color: black !important;
  margin-bottom: 5px;
}

.doc-upload-input {
  display: flex;
  /* justify-content: space-between; */
  position: relative;
}

.doc-upload-input .kycpreview {
  position: absolute !important;
  top: 0;
  left: 290px;
}

.pass_group_main {
  position: relative;
}

.pass_group_main .custom_form_control {
  padding-right: 0px;
  width: 100%;
}

.pass_group_main .custom_form_control input {
  padding-right: 45px !important;
}

.title-name-upload {
  margin-bottom: 10px;
}

.title-name-upload label,
.title-name-upload label:hover {
  width: 100%;
  background: transparent;
  border-radius: 4px;
  text-transform: capitalize;
  border: 1px solid #d1d5db;
  box-shadow: none;
  color: #d1d5db;
  flex-direction: column;
  height: 300px;
}

.title-name-upload label span {
  margin-top: 10px;
}

.comman-flex-justify {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: space-between;
}

.mb-20px {
  margin-bottom: 20px;
}

.signin-btn-main .comman-primary-btn {
  min-width: 100%;
}

.roundmainimg,
.roundmainimg3 {
  -webkit-animation: rotating 12s linear infinite;
  animation: rotating 12s linear infinite;
}

.roundmainimg2 {
  /* width: 291px;
  height: 280px;
  position: absolute;
  -webkit-animation: rotating1 12s linear infinite;
  animation: rotating1 12s linear infinite; */

  width: 74px;
  height: 35px;
  position: absolute;
  animation: rotating1 12s linear infinite;
  right: 5px;
  top: 3px;
}

.roundmainimg {
  width: 290px;
  height: 290px;
  object-fit: cover;
  position: absolute;
}

.hero-bgimage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 9px;
  left: 0;
  object-fit: contain;
}

.roundmainimg3 {
  /* position: absolute;
  width: 247px;
  height: 234px; */

  position: absolute;
  width: 28px;
  height: 28px;
  top: 6px;
  right: 28px;
}

@keyframes rotating {
  0% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes rotating1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes crescendo {
  0% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1.3);
    letter-spacing: 5px;
  }
}

.resend-main {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 10px 0px !important;
  font-family: "Poppins-Medium";
}
.upload-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
}
.user-title-flex {
  display: flex;
  align-items: center;
}
.admin-title-icon {
  width: 18px;
  height: 18px;
  margin-right: 7px;
}
.bg-white {
  background: #ffffff;
  border-radius: 6px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px; */
  box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
  height: 100%;
  padding: 20px;
}
/* .analysis-chart-main{
  padding: 45px 41px 17px 19px;
  min-height: 440px;
} */
.country-code-main .country-code-details .MuiInput-underline {
  min-height: 40px;
}
.change-pass-fl-start {
  justify-content: flex-start;
}
.otp-main .form-group .form-control input {
  min-height: 45px;
}
/* .userdetail-profilesec{
  max-width: 700px;
  border: 1px solid #c9c8cd;
  border-radius: 6px;
  padding: 15px;
}
.user-detail-profile-section{
  display: flex;
} */

.recharts-wrapper.line-chart-main {
  width: 100% !important;
}

.recharts-wrapper.line-chart-main .recharts-surface {
  width: 100%;
  object-fit: cover;
}
.recharts-legend-wrapper {
  width: 100% !important;
}
.presale-input-mr-15 {
  margin-right: 15px;
}
.input-box .ck-content {
  min-height: 300px;
}
.MuiDataGrid-columnSeparator {
  display: none;
}
.icon-setclose {
  display: none;
}
.stack-box-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  /* margin-top: 15px; */
}
.admin-dash-right {
  width: 42px;
  height: 42px;
  background: #e8e6fc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.purchase-card .admin-dash-right {
  background: #dcf6e8;
}
.br-boottom-none {
  border-bottom: none !important;
}
.set-code-box-main {
  margin-bottom: 12px;
}
.referrel-card {
  background: #ffffff;
  border-radius: 6px;
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 10px; */
  box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
  padding-bottom: 20px;
}
.left-right-box {
  background: #f1f1f2;
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}
.left-right-box-main {
  display: flex;
  align-items: center;
}
.refferal-search-right {
  justify-content: flex-end;
}
.referral-list-tree-btn {
  margin-bottom: 20px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
/* .token-presale-background {
  align-items: center;
  background-image: url(/static/media/bgimg.cad637f….png);
  background-size: cover;
  display: block;
  height: 100%;
  justify-content: center;
  min-height: calc(100vh - 126px);
  padding: 37px 0 30px;
} */
.w-100 {
  max-width: 100% !important;
  width: 100% !important;
}
.waitlist-sub {
  justify-content: end !important;
}
.pos-rel {
  position: relative;
}
.header-main-details {
  background: linear-gradient(72.47deg, #18c77763 22.16%, #3ca4b5d6 76.47%);

  padding: 10px 20px;
}
.presale-login-btn {
  color: #7367f0 !important;
  background: #ffffff !important;
  font-size: 14px;
  font-family: "Poppins-Medium";
}
.country-code-main .multiline-input {
  border: 1px solid #c9c8cd;
}
.supp-send-btn {
  margin-top: 20px;
}
.close-icon-modal {
  height: 13px;
  width: 13px;
}
.close-modal {
  position: absolute;
  right: 10px;
  top: -2px;
  z-index: 9;
}
.colse-position {
  position: relative;
}
.set-modal-line {
  position: relative;
}
.set-modal-line:after {
  content: "";
  background: #7f7f7f4a;
  bottom: -25px;
  left: 0px;
  right: 0;
  /* width: 2px; */
  height: 2px;
  display: flex;
  position: absolute;
}
.primary-btn-main .primary-btn,
.primary-btn-main .primary-btn:hover {
  background-color: #32b0c4;
  border: 1px solid #32b0c4 !important;
  border-radius: 5px;
  color: #fff;
  font-family: "Poppins-Medium";
  font-size: 16px;
  justify-content: center;
  line-height: 19px;
  padding: 9px 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}
.qr-btn {
  margin-top: 16px;
}
.referral-table .MuiDataGrid-footerContainer {
  display: none;
}
.referral-table .MuiDataGrid-cell {
  color: #726f7c;
  font-family: "Poppins-Regular";
}
.referral-table .referral-main {
  height: 300px;
  width: 100%;
}
.title-admin-drop,
.title-admin-drop span {
  display: flex;
  align-items: center;
}
.ref-inner::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.ref-inner::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ref-inner::-webkit-scrollbar-thumb {
  background: #d3d6da;
}

/* Handle on hover */
.ref-inner::-webkit-scrollbar-thumb {
  background: #555;
}

.data-table-wrap .admin-sub-title {
  color: #5d5a68;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-bold";
  margin-bottom: 20px;
}
.data-table-wrap .MuiDataGrid-footerContainer {
  display: none;
}

.user-waitlist-main .user-list-table .table .table-th:nth-child(1),
.user-waitlist-main .user-list-table .table .table-td:nth-child(1) {
  width: 10%;
}

.user-waitlist-main .user-list-table .table .table-th:nth-child(2),
.user-waitlist-main .user-list-table .table .table-td:nth-child(2) {
  width: 10%;
}

.user-waitlist-main .user-list-table .table .table-th:nth-child(3),
.user-waitlist-main .user-list-table .table .table-td:nth-child(3) {
  width: 15%;
}

.user-waitlist-main .user-list-table .table .table-th:nth-child(4),
.user-waitlist-main .user-list-table .table .table-td:nth-child(4) {
  width: 15%;
}
.user-waitlist-main .user-list-table .table .table-th:nth-child(5),
.user-waitlist-main .user-list-table .table .table-td:nth-child(5) {
  width: 10%;
}

.user-waitlist-main .user-list-table .table .table-th:nth-child(6),
.user-waitlist-main .user-list-table .table .table-td:nth-child(6) {
  width: 10%;
}

.user-waitlist-main .user-list-table .table .table-th:nth-child(7),
.user-waitlist-main .user-list-table .table .table-td:nth-child(7) {
  width: 15%;
}

.user-waitlist-main .user-list-table .table .table-th:nth-child(8),
.user-waitlist-main .user-list-table .table .table-td:nth-child(8) {
  width: 15%;
}
.user-waitlist-main .user-list-table .table .table-th:nth-child(9),
.user-waitlist-main .user-list-table .table .table-td:nth-child(9) {
  width: 15%;
}
.page-table-main .table .table-td:nth-child(1) a {
  color: #6b7280;
  text-decoration: none;
}
/* .pagination-main{
  display: none;
} */
.row-mb {
  margin-bottom: 20px;
}
.billionair-tokenpre-card {
  box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent !important;
}
/* .admin-header-profile-box{
  display: none;
} */
.my-referral-main .text-copy-box {
  display: flex;
  align-items: center;
  margin-right: 0;
}
.my-referral-main .dash-text-copy-box {
  margin-right: 0;
}
/* .my-referral-main .admin-sub-title-main{
margin-bottom: 20px;
} */
.my-referral-title {
  margin-bottom: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.display-none {
  display: none !important;
}
.column-mb {
  margin-bottom: 16px;
}
.bg-gradiant {
  background-image: linear-gradient(
    72.47deg,
    rgb(63 227 152) 22.16%,
    rgb(50 176 196) 76.47%
  ) !important;
  border: none !important;
}
.waitlist-check-main .checkbox-lable {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
  color: #5b5865 !important;
}
.checkbox-lable .terms-link {
  color: #32b0c4;
  text-decoration: none;
}
.modal-close-icon {
  filter: invert();
  width: 20px;
  position: absolute;
  right: 0;
  top: 0;
}
.app-download-ic {
  width: 20px;
}
.app-deposit-main {
  display: flex;
  align-items: center;
  /* display: none; */
}
.res-deposite-btn {
  background: none;
  color: #32b0c4;
  border: none;
  cursor: pointer;
  margin: 0px 5px !important;
}
.wallet-ic {
  margin: 0 10px;
  width: 25px;
}
.set-qr-btn-modal .dash-qr-title {
  color: #ffffff !important;
}
/* .qr-modal-custom{
  width: 0;
  min-height: 500px;
  background: red;
  position: absolute;
  right: -500px;
  top: 150px;
  z-index: 9999;
  transition: all .5s ease;
  overflow: hidden;
  display: none;
} */
/* .qr-modal-custom.active{
  right: 0;
width: 500px;
max-width: 500px;
overflow: auto;
  display: block;
} */
/* .filter-main .MuiPaper-root {
  background-color: transparent;
  box-shadow: none;
  top: 162px;
}
.filter-main .MuiBackdrop-root {
  background-color: transparent;
} */

.dash-text-copy-box {
  display: flex;
  align-items: center;
}
.hash-key-input {
  max-width: 100% !important;
}
.input-design-div {
}
.network-dropdown {
  display: flex;
  align-items: center;
}
.network-dropdown-ic {
  margin-right: 10px;
}
.hash-key-text {
  margin-bottom: 15px;
}

.terms-and-condition-modal {
  border: none !important;
}
/* token-table css */
.token-table-main .table-th {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  padding: 10px 5px 10px 0px;
}
.token-table-main .table-td {
  text-align: left;
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  padding: 10px 5px 10px 0px;
}
.token-table-main .table .table-th:first-child,
.token-table-main .table .table-td:first-child {
  width: 12%;
  padding-left: 15px;
}
.token-table-main .table .table-th:nth-child(1),
.token-table-main .table .table-td:nth-child(1) {
  width: 16%;
}

.token-table-main .table .table-th:nth-child(2),
.token-table-main .table .table-td:nth-child(2) {
  width: 16%;
}
.token-table-main .table .table-th:nth-child(3),
.token-table-main .table .table-td:nth-child(3) {
  width: 14%;
}
.token-table-main .table .table-th:nth-child(4),
.token-table-main .table .table-td:nth-child(4) {
  width: 12%;
}
.token-table-main .table .table-th:nth-child(5),
.token-table-main .table .table-td:nth-child(5) {
  width: 18%;
}
.token-table-main .table .table-th:nth-child(6),
.token-table-main .table .table-td:nth-child(6) {
  width: 18%;
}

.main-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 90%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  padding: 16px;
}
.modal-button-box {
  margin-top: 13px;
  display: flex;
  justify-content: flex-end;
}
.modal-button-box button {
  font-family: Poppins-SemiBold;
  background: transparent linear-gradient(90deg, #089cbc 0%, #52ea9c 100%) 0% 0%
    no-repeat padding-box;
  color: white;
  width: 15px;
  height: 30px;
  font-size: 10px;
}
.modal-button-box button:hover {
  background-color: brown;
  color: white;
  width: 15px;
  height: 30px;
  font-size: 10px;
}

.token-search-main {
  margin-left: 500px;
}
.edit-main .modal-textfield {
  border: 1px solid #004554;
  border-radius: 10px;
}
/* .edit-main fieldset{
border: 1px solid #004554;
} */

/* my income css */

.table-design-main .table-header tr td:first-child {
  margin-left: 50px;
}
.table-design-main .table-data {
  padding: 17px 10px 17px 5px;
}
.my_income-table-main .table .table-th:nth-child(1),
.my_income-table-main .table .table-td:nth-child(1) {
  width: 18%;
}
.my_income-table-main .table .table-th:nth-child(2),
.my_income-table-main .table .table-td:nth-child(2) {
  width: 15%;
}
.my_income-table-main .table .table-th:nth-child(3),
.my_income-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.my_income-select {
  min-width: 200px;
  min-height: 30px !important;
}
.my_income-select .select-tab {
  height: 40px;
  margin-bottom: 10px;
  min-height: 40px;
}
/* Swap and Withdraw Styles */
.swap-box-main {
  padding: 10px;
}

.swap-box-content {
  align-items: center;
  background-color: #fff;
  border: 1px solid #2cb4c921;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px;
}

.input-box .form-lable {
  color: #000;
  font-family: Poppins-Medium;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.swap-input-box .form-group .form-control input {
  background-color: initial;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: #353945;
  font-family: Poppins-Regular;
  font-size: 13px;
  line-height: 18px;
  /* padding: 11px 16px; */
}

.form-group .form-control input::placeholder {
  color: #08080a;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
}

.excha-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 21px;
}

.swap-box-wrapper {
  position: relative;
}

.available-swipe-box {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #f7c02e; */
  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
  background-image: linear-gradient(72.47deg, #3fe398 22.16%, #32b0c4 76.47%);
  top: 50%;
  transform: translateY(-50%);
}

.available-swiper-icon {
  width: 25px;
}

.available-token-sec {
  align-items: center;
  /* background-image: linear-gradient(72.47deg,#3fe398 22.16%,#32b0c4 76.47%); */
  background: #32b0c42b;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin: 0 0 10px 0;
}

.available-token-sec .available-titles,
.available-token-sec .available-data {
  color: #000000;
  font-family: Poppins-Regular;
  font-size: 15px;
  line-height: 18px;
  margin: 0;
}

.btn-main-primary .btn-primary,
.btn-main-primary .btn-primary:hover {
  background-image: linear-gradient(72.47deg, #3fe398 22.16%, #32b0c4 76.47%);

  border-radius: 4px;
  width: 100%;
  /* max-width: 380px; */
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  padding: 10px 15px;
  text-transform: capitalize;
}

.excha-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 21px;
}

.swap-box-wrapper {
  position: relative;
}

.full-width .input-box.swap-input-box {
  max-width: 100%;
}
.full-width.swap-box-content {
  flex-wrap: wrap;
}

/* Withdraw */

.crypto-token-form {
  margin-top: 25px;
}
.withdraw-token-form {
  margin-top: 0px;
}
.crypto-token-form-withdraw {
  margin-top: 25px;
}
.crypto-token-form .dropdown-box .form-control .dropdown-select,
.transfer-sec .dropdown-box .form-control .dropdown-select {
  border: 1px solid #d1d5db !important;
  border-radius: 8px;
}

.input-box {
  margin-bottom: 15px;
}
.input-box-panel {
  margin-bottom: 0px;
}
/* .btn-main-primary .withdraw-btn {
    max-width: 500px;
} */

.crypto-token-form .withdraw-main-btn {
  text-align: center;
  margin: 23px 0px 0px 0px;
}
.crypto-token-form .withdraw-main-btn-btn {
  text-align: center;
  margin: 15px 0px 0px 0px;
}
.swap-box-wrapper .swap-input-box .MuiSelect-select {
  padding: 10px 32px 10px 10px;
}
.swap-input-box .MuiInputBase-root {
  width: 100%;
  border-radius: 8px;
  border: 1px solid #d1d5db !important;
  height: 35.97px;
}
.swap-input-box .form-group .form-control input {
  border-bottom: 0px solid #35394533 !important;
}
.swap-input-box {
  max-width: 48%;
  width: 100%;
}
.admin-sub-title-main .title {
  font-size: 13px;
  font-weight: 700;
  font-family: Poppins-Medium;
}
.admin-sub-title-main .tab-btn .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: #111827;
}
.transfer-verify-verify {
  border-bottom: 0px solid;
  color: green;
}

/* user staklist css */
.table-td .restak-button {
  font-size: 12px;
  background: linear-gradient(
    72.47deg,
    rgb(63, 227, 152) 22.16%,
    rgb(50, 176, 196) 76.47%
  );
  color: white;
  font-family: "Poppins-Medium";
  height: 25px;
  text-transform: capitalize;
}

.confirmation-box .con-btn {
  display: flex;
  justify-content: center;
}

.kyc-view-section {
  padding: 15px;
}
.kyc-view-second-section {
  padding: 15px;
  margin-top: 10px;
}

/* mining progress  */

.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
}
.dialog-box .dialog-title {
  border-image-source: linear-gradient(to left, #3fe398, #32b0c4);
  font-family: "Poppins-Medium";
  text-transform: capitalize;
}
.income-card {
  min-width: 207px;
}
/* login disable btn */
.login-btn-main .disabled {
  background-color: #aae3eb;
  cursor: not-allowed;
  color: #32b0c4;
}

.user-withdraw-list table thead tr th:nth-child(1),
.user-withdraw-list table tbody tr td:nth-child(1) {
  width: 12% !important;
}
.user-withdraw-list table thead tr th:nth-child(2),
.user-withdraw-list table tbody tr td:nth-child(2) {
  width: 17% !important;
}
.user-withdraw-list table thead tr th:nth-child(3),
.user-withdraw-list table tbody tr td:nth-child(3) {
  width: 13% !important;
}
.user-withdraw-list table thead tr th:nth-child(4),
.user-withdraw-list table tbody tr td:nth-child(4) {
  width: 6% !important;
}
.user-withdraw-list table thead tr th:nth-child(5),
.user-withdraw-list table tbody tr td:nth-child(5) {
  width: 10% !important;
}
.user-withdraw-list table thead tr th:nth-child(6),
.user-withdraw-list table tbody tr td:nth-child(6) {
  width: 8% !important;
}
.user-withdraw-list table thead tr th:nth-child(7),
.user-withdraw-list table tbody tr td:nth-child(7) {
  width: 7% !important;
}
.user-withdraw-list table thead tr th:nth-child(8),
.user-withdraw-list table tbody tr td:nth-child(8) {
  width: 5% !important;
}
.user-details-status-active {
  background-color: green;
  color: #ffffff;
  text-align: center;
}
.user-details-status-deactive {
  background-color: red;
  color: #ffffff;
  text-align: center;
}
.admin-dashboard-box .admin-page-title {
  color: #5d5a68;
  font-size: 22px;
  line-height: 35px;
  font-family: "Poppins-SemiBold";
  /* margin-bottom: 20px; */
}

.admin-dash-price .basic-card-detail {
  font-size: 14px;
  font-family: "Poppins-SemiBold";
  color: #5d5a68 !important;
}
.basic-card-detail p {
  font-size: 14px;
  font-family: "Poppins-SemiBold";
  color: #5d5a68 !important;
}
.basic-card-detail p:nth-child(2) {
  font-size: 15px;
  font-family: "Poppins-Regular";
  color: #5d5a68 !important ;
}
.net-ex-tabbar
  .MuiFormControlLabel-labelPlacementEnd
  .MuiFormControlLabel-label {
  font-family: "Poppins-Regular";
  font-size: 14px;
}
.wallet-withdraw-modal-balance {
  align-items: center;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  margin: 0 0 10px 0;
  border: 1px solid gray;
}
/* wallet list  */
.admin-user-wallet-list .table .table-th:nth-child(1),
.admin-user-wallet-list .table .table-td:nth-child(1) {
  width: 10%;
}
.admin-user-wallet-list .table .table-th:nth-child(2),
.admin-user-wallet-list .table .table-td:nth-child(2) {
  width: 15%;
}
.admin-user-wallet-list .table .table-th:nth-child(3),
.admin-user-wallet-list .table .table-td:nth-child(3) {
  width: 15%;
}
.admin-user-wallet-list .table .table-th:nth-child(4),
.admin-user-wallet-list .table .table-td:nth-child(4) {
  width: 12%;
}
.admin-user-wallet-list .table .table-th:nth-child(5),
.admin-user-wallet-list .table .table-td:nth-child(5) {
  width: 12%;
}
.admin-user-wallet-list .table .table-th:nth-child(6),
.admin-user-wallet-list .table .table-td:nth-child(6) {
  width: 12%;
}
.admin-user-wallet-list .table .table-th:nth-child(7),
.admin-user-wallet-list .table .table-td:nth-child(7) {
  width: 12%;
}
.admin-user-wallet-list .table .table-th:nth-child(8),
.admin-user-wallet-list .table .table-td:nth-child(8) {
  width: 12%;
}
.wallet-modal-box-set {
  width: 100%;
  border-radius: 14px;
  border: 1px solid #d1d5db !important;
}
.wallet-modal-box-set p {
  width: 80%;
  padding: 8px 15px;
  min-width: 240px;
}
.wallet-text-line p {
  font-size: 15px;
  line-height: 15px;
  margin-left: 18px;
  font-family: "Poppins-Regular";
}
.wallet-text-line-box {
  display: flex;
}
.input-box-admin-wallet {
  display: flex;
  align-items: center;
}
.input-box-admin-wallet .form-lable {
  margin-bottom: 0px !important;
  margin-right: 10px;
}
.wallet-text-line-box-1 {
  margin-top: 13px;
}
.wallet-modal-set-main {
  max-width: 752px !important;
  width: 100% !important;
}
.wallet-modal-set-height {
  overflow: auto;
  height: 512px;
}
/* width */
.wallet-modal-set-height::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.wallet-modal-set-height::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.wallet-modal-set-height::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.wallet-modal-set-height::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.primary-btn-main-user .wallet-modal-btn-set {
  margin-left: 10px !important;
}
.modal-wallet {
  display: flex;
}

/* SOCIAL */
.social-tablist .facebook-icon {
  width: 50px;
}
.social-tablist .instagram-icon {
  width: 40px;
}
.social-tablist .youtube-icon {
  width: 50px;
}
.social-tablist .twitter-icon {
  width: 30px;
}
.social-tablist .discord-icon {
  width: 40px;
}
.social-tablist label {
  font-size: 50px;
}
.tab-label {
  font-size: 14px;
  font-weight: bold;
  color: #726f7b;
  /* Add more custom styles as needed */
}
.social-textfield {
  border: 2px solid;
}
.sub-tab-box {
  padding: 15px;
}
.sub-tab-box .social-text {
  margin-bottom: 10px;
}
.approve-icon .reject {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.dialog-box .dialog-box-social {
  border-image-source: linear-gradient(to left, #3fe398, #32b0c4);
  width: 500px;
}
.dialog-box-social .dialog-title-social {
  font-size: 20px;
}

.social-approve-reject-button {
  display: flex;
  justify-content: flex-end;
margin-top: 10px;
}
.social-approve-reject-button .social-btn,
.social-approve-reject-button .social-btn:hover {
  width: 50px;
  color: white;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(47, 43, 61, 0.14), 0 0 transparent, 0 0 transparent;
  border: none;
  min-width: 95px;
  min-height: 40px;
  padding: 6px 14px 5px;
  font-size: 12px;
  /* border: 1px solid #1acb41; */
  line-height: 15px;
  border-radius: 4px;
  text-transform: capitalize;
  border: 1px solid;
  text-decoration: none;
  height: 20px;
}
.social-approve-reject-button .social-btn:disabled {
  width: 50px;
  color: #878585;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(47, 43, 61, 0.14), 0 0 transparent, 0 0 transparent;
  border: none;
  min-width: 95px;
  min-height: 40px;
  padding: 6px 14px 5px;
  font-size: 12px;
  /* border: 1px solid #1acb41; */
  line-height: 15px;
  border-radius: 4px;
  text-transform: capitalize;
  border: 1px solid;
  text-decoration: none;
  height: 20px;
}
.social-approve-reject-button .approve-btn,
.social-approve-reject-button .approve-btn:hover {
  background: green;
}
.social-approve-reject-button .reject-btn,
.social-approve-reject-button .reject-btn:hover {
  background: red;
}

.social-approve-reject-button .approve-btn-disabled:disabled {
  background: green;
margin-right: 5px;
}
.social-approve-reject-button .reject-btn-disabled {

  background: red;
}

.view-social-main .social-table-list .table-td{
  border-bottom: 1px solid #dedde0;

}
.view-social-main .social-table-list .table .table-th:nth-child(1), .table .table-td:nth-child(1) {
  width: 10% !important;
}


.view-social-main .social-table-list .table .table-th:nth-child(2),  .table .table-td:nth-child(2) {
    width: 14% 
}
.view-social-main .social-table-list .table .table-body:last-child {
  
}
.table-social-main .table-social-ic{
width: 40px;
height: 40px;
object-fit: contain;
}
.table-social-main{

margin-right: 10px;
display: flex;
align-items: center;
justify-content: center;
position: relative;
}
.table-social-main .table-social-check{

width: 20px;
height: 20px;
object-fit: contain;
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: none;
}
.table-social-main.active .table-social-check{
  display: block;
}
.table-social-main:last-child{

margin-right: 0;
}
.table-social{
  display: flex;
  align-items: center;
}
.primary-btn-main-comman .social-submit{
min-width: max-content;
}

.active-user-list-table .table .table-th:nth-child(1),
.active-user-list-table .table .table-td:nth-child(1) {
  width: 13%;
}
.active-user-list-table .table .table-th:nth-child(2),
.active-user-list-table .table .table-td:nth-child(2) {
  width: 13%;
}
.active-user-list-table .table .table-th:nth-child(3),
.active-user-list-table .table .table-td:nth-child(3) {
  width: 13%;
}
.active-user-list-table .table .table-th:nth-child(4),
.active-user-list-table .table .table-td:nth-child(4) {
  width: 13%;
}
.active-user-list-table .table .table-th:nth-child(5),
.active-user-list-table .table .table-td:nth-child(5) {
  width: 13%;
}
.active-user-list-table .table .table-th:nth-child(6),
.active-user-list-table .table .table-td:nth-child(6) {
  width: 10%;
}
.active-user-list-table .table .table-th:nth-child(7),
.active-user-list-table .table .table-td:nth-child(7) {
  width: 10%;
}
.active-user-list-table .table .table-th:nth-child(8),
.active-user-list-table .table .table-td:nth-child(8) {
  width: 10%;
}
.active-user-list-table .table .table-th:nth-child(9),
.active-user-list-table .table .table-td:nth-child(9) {
  width: 5%;
}
.reason-modal-open .reason-modal input{
  min-height: 50px;
}
.reason-modal-open .rejection-modal{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: white;
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: 24px;
  padding: 16px;
}
.rejection-reason-main {
  padding: 10px;
  width: 100%;
  display: flex;
}
.grid-main .grid-items{
  text-align: left;
}
.rejection-reason-main .rejection-reason-title{
  font-family: "Poppins-Regular";
  font-weight: bold;
  font-size: 15px;
}
.rejection-reason-main .rejection-reason-data{
margin-left: 10px;
}