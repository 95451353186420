@media screen and (min-width: 1280px) and (max-width: 1365px) {
    .card-icon {
        width: 30px;
        height: 30px;
    }
    .card-title-icon {
        width: 15px;
        height: 15px;
    }
    .bg-white{
        padding: 15px;
    }
    .card-dash-text {
        font-size: 12px;
        line-height: 16px;
    }
}

@media screen and (min-width: 992px) and (max-width: 1279px) {}
@media screen and (min-width: 992px) and (max-width:1199px) {
    .presale-box-sec{
        margin: 15px 0;
    }
    .admin-dashboad-row {
        margin-bottom: 35px;
    }
    .analysis-chart-sec{
        margin-top: 15px;
    }
}
@media screen and (max-width:991px) {}
@media screen and (min-width: 768px) and (max-width: 991px) {
   
    .admin-dashboard-inner-box {
        padding: 15px 10px;
    }
    .admin-dashboard-inner-box .user-dash-price {
        font-size: 20px;
        line-height: 35px;
    }
    .dash-text {
        font-size: 13px;
    }
}
@media screen and (max-width:767px) {
    .card-col-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
@media screen and (min-width: 320px) and (max-width:374px) {}

@media only screen and (max-width: 320px) {}