/* .input-box .form-lable {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0px;
    font-family: "Poppins-Medium";
    margin-bottom: 10px;
    margin-top: 0px;
    color: #000000;
} */
.flex-input {
  display: flex;
  margin-bottom: 20px;
}

.join-wait-input input {
  padding: 7px 15px !important;
  border-radius: 4px !important;
  min-width: 400px !important;
}
.set-join-btn-box {
  margin-left: 20px;
}
.setting-content .MuiGrid-container {
  margin-top: 0;
}
