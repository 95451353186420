@media screen and (min-width: 1600px) and (max-width: 1919px) {}
@media screen and (min-width: 1366px) and (max-width: 1599px) {}
@media screen and (min-width: 1280px) and (max-width: 1365px) {}
@media screen and (min-width: 992px) and (max-width: 1279px) {}
@media (max-width: 767px) {
    .mob-header-left{
        display: flex;
        align-items: center;
    }
    .mob-header-left .admin-sidebar-logo {
        max-width: initial;
        height: 50px;
    }
    .excha-card {
        padding: 15px;
    }
    .swap-input-box {
        max-width: 100%;
    }
}