* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* font-family: "Urbanist-Regular"; */
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../fonts/Poppins/poppins-bold.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

/* .grid-main .grid-item {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding: 0px;
} */

/* .page-bg {
  background-image: url(../images/png/bgimg.png);
} */

/* .flex-all {
  display: flex;
  justify-content: space-between;
} */

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.form-control-textarea:focus-visible {
  outline: none !important;
}

/* .form-group .form-control input {
  border: 1px solid #849ec1;
  width: 100%;
  padding: 11.45px 16px;
  background-color: transparent;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
} */

.form-group .text-custom-control {
  width: 100%;
  min-height: 138px;
  background: transparent;
  border-radius: 14px;
  font-family: "Poppins-Regular";
  border: 1px solid #849ec1;
}

/* .form-group .form-control input::placeholder {
  color: #08080a;
  font-size: 14px;
  line-height: 20px;
  font-family: "Poppins-Regular";
  opacity: 1;
} */

.form-group .form-control .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #849ec1;
  border-width: 0px;
}

fieldset {
  border-color: transparent !important;
  top: 0px !important;
}

.btn-main .btn,
.btn-main .btn:hover {
  width: 100%;
  max-width: 380px;
  /* background: #74540b; */
  background: #5a320a;
  border-radius: 0px;
  padding: 5px 30px;
  min-height: 45px;
  font-family: "Poppins-Regular";
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  text-transform: capitalize;
  box-shadow: none;
}

.border-btn-main .border-btn {
  border: 1px solid #000000;
  border-radius: 4px;
  background-color: transparent;
  padding: 6px 14px 5px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  font-family: "Poppins-Regular";
  text-transform: capitalize;
}

.border-btn-main .border-btn:hover {
  background-color: transparent;
  padding: 6px 14px 5px;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  min-width: 100px;
  border: 1px solid #000000;
  border-radius: 4px;
}

/* .common-para {
  font-size: 14px !important;
  line-height: 21px !important;
  font-family: "Poppins-Regular";
} */

/* start admin login css */
/* .center-logo-login {
  text-align: center;
  margin-bottom: 20px;
} */

/* .admin-login-main {
  margin: auto;
  max-width: 380px;
  width: 100%;
} */

/* .admin-login-box {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .login-btn-main .login-btn {
  margin-top: 28px;
} */

/* .checkbox-main .checkbox-lable {
  color: #6b7280;
  margin-left: 0px;
  margin-right: 0px;
} */

/* .checkbox-lable .MuiCheckbox-root {
  padding: 0px;
  margin-right: 13px;
} */

/* .checkbox-lable .MuiFormControlLabel-label {
  font-family: "Poppins-Regular";
  font-size: 14px;
  line-height: 21px;
} */

/* .admin-login-inner .admin-wel-text {
  color: #5a320a;
  font-size: 24px;
  line-height: 25px;
  font-family: "Poppins-Bold";
  margin-bottom: 4px;
  text-align: center;
} */

/* .admin-login-inner .admin-sign-para {
  margin-bottom: 32px;
  color: #6b7280;
  text-align: center;
} */

/* .input-box .form-lable {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0px;
  font-family: "Poppins-Medium";
  margin-bottom: 10px;
  margin-top: 0px;
  color: #000000;
} */

/* .input-box-admin {
  margin-bottom: 20px;
} */

/* .admin-forgot-para {
  color: #004dd1;
} */

/* .input-box .MuiInputBase-root {
  width: 100%;
} */

.input-box .MuiInputBase-root fieldset:hover {
  border-color: transparent;
}

/* .checkbox-main .MuiSvgIcon-root {
  color: #74540b;
  width: 20px;
  height: 20px;
} */

/* column login start */
.login-main-row {
  display: flex;
  flex-wrap: wrap;
}

.log-col-6 {
  width: 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.login-left-image {
  background-image: linear-gradient(140deg, #000000, #004dd1 180%);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
}

/* .center-logo-login .logo-admin {
  height: 100px;
  object-fit: contain;
} */

/* .card-login-main {
  background: #ffffff;
  padding: 30px 20px; */
/* border-radius: 10px; */
/* box-shadow: 0px -1px 7px #ffffff; */
/* } */

/* column login end */

/* end admin login css */

/* start admin dashboard page structure css */

.dashboard-left-main {
  /* flex: 0 0 290px;
  max-width: 290px; */
  flex: 0 0 260px;
  max-width: 260px;
  width: 100%;
}

/* .dashboard-right-main {
  flex: 1;
} */

.dashboard-containt-main {
  background-color: #ffffff;
  min-height: 100vh;
  padding: 88px 24px 24px 24px;
}

.dashboard-main {
  display: flex;
  width: 100%;
}

/* end admin dashboard page structure css */

/* start admin sidebar css */

/* .admin-sidebar-icons {
  margin-right: 8px;
  display: flex;
} */

/* .admin-sidebar-main {
  height: 100vh;
  left: 0;
  overflow: auto;
  padding: 0px 16px;
  position: fixed;
  top: 0;
  width: 260px;
  z-index: 999;
  border-right: 1px solid #e5e7ebab;
  background: #27170a;
  transition: 0.5s all;
} */

/* .admin-sidebar-logo {
  max-width: 80px;
  height: 55px;
  width: 100%;
  object-fit: contain;
  padding: 4px 0;
  margin-top: 4px;
} */

/* .admin-sidebar-logo-main {
  text-align: center;
} */

/* .admin-sidebar-list-main .admin-sidebar-list {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-listitem {
  padding: 0px;
}

.admin-sidebar-list-main .admin-sidebar-link {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  color: #ffffff;
  display: flex;
  padding: 10px 12px 10px;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
  text-decoration: none;
}

.admin-sidebar-list-main .admin-sidebar-link.active {
  color: #fff;
  border-radius: 4px;
  background: #5a320a;
}

.admin-sidebar-list-main .admin-sidebar-link.active svg path {
  fill: #000000;
} */

/* end admin sidebar css */

/* start admin header css */
/* .drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
} */

/* .drop-header-menu .MuiPaper-rounded {
  box-shadow: 10px 10px 20px #0000001a;
  border: 1px solid #e5e7eb;
  margin-top: 13px;
  border-radius: 1px;
} */

/* .drop-header {
  height: 15px;
  width: 15px;
  margin-right: 6px;
} */

/* .drop-header-menu .drop-header-menuitem {
  font-size: 12px;
  line-height: 14px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  border-bottom: 1px solid #e5e7eb;
  padding: 11px 16px;
  text-decoration: none;
} */

/* .text-decoration {
  text-decoration: none;
} */

/* .drop-header-menu ul {
  padding: 0px;
} */

/* .admin-header-main {
  background: #27170a;
  position: fixed;
  top: 0px;
  left: 260px;
  right: 0px;
  z-index: 9;
  padding: 14px 20px 14px 20px;
  border-bottom: 1px solid #e5e7eb;
  transition: 0.5s all;
} */

/* .admin-sidebar-list-main {
  margin-top: 15px;
} */

/* .admin-header-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */

.admin-header-icon-box {
  margin-right: 11px;
  display: flex;
}

.admin-header-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
}

/* .admin-header-profile-icon {
  height: 36px;
  width: 36px;
  margin-right: 12px;
  object-fit: cover;
  border-radius: 50%;
} */

/* .flex-drop-main {
  display: flex;
  align-items: center;
}

.flex-drop-main .admin-header-drop {
  font-size: 16px;
  font-family: "Poppins-Medium";
  line-height: 19px;
  color: #ffffff;
  text-align: start;
}

.flex-drop-main .admin-drop-user {
  font-size: 14px;
  font-family: "Poppins-Bold";
  line-height: 21px;
  color: #6b7280;
  text-align: start;
} */

/* end admin header css */

/* start admin private sale */

.private-sale-fileld {
  width: 620px;
  margin-top: 15px !important;
}

.primary-btn-main .sale-btn,
.primary-btn-main .sale-btn:hover {
  background-color: #001436;
  padding: 15px;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Semibold";
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 0 !important;
  border-radius: 5px;
  width: 100%;
}

/* end admin private sale  */
.userbar {
  text-decoration: none;
  color: #000000;
}

/* start admin dashboard page */

/* .dashboard-content .admin-page-title {
  color: #111827;
  font-size: 26px;
  line-height: 39px;
  font-family: "Poppins-SemiBold";
} */

.admin-dashboard-inner-box {
  border: 1px solid #d1d5db;
  max-width: 245px;
  width: 100%;
  height: 90px;
  border-radius: 6px;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
}

.admin-dashboard-inner-box .admin-dash-text {
  color: #6b7280;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins-Regular";
}

.admin-dashboard-inner-box .admin-dash-price {
  color: #111827;
  font-size: 30px;
  line-height: 46px;
  font-family: "Poppins-Medium";
}

/* .dash-icons {
  height: 50px;
  width: 50px;
  object-fit: contain;
} */

/* .page-table-main .table-container {
  padding-bottom: 7px;
} */

/* .page-table-main .table { */
/* margin: 0px 19px; */
/* } */

/* .page-table-main .table-th {
  font-size: 12px;
  line-height: 18px;
  color: #111827;
  font-family: "Poppins-Medium";
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #d1d5db;
} */

/* .page-table-main .table .table-td:first-child {
  color: #111827;
} */

/* .page-table-main .table-td {
  font-size: 12px;
  line-height: 18px;
  color: #6b7280;
  font-family: "Poppins-Regular";
  padding: 10px 10px 10px 0px;
  border-bottom: 1px solid #d1d5db;
} */

/* Main Table */
/* .page-table-main .table .table-th:nth-child(1),
.page-table-main .table .table-td:nth-child(1) {
  width: 10%;
}

.page-table-main .table .table-th:nth-child(2),
.page-table-main .table .table-td:nth-child(2) {
  width: 10%;
}

.page-table-main .table .table-th:nth-child(3),
.page-table-main .table .table-td:nth-child(3) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(4),
.page-table-main .table .table-td:nth-child(4) {
  width: 15%;
}

.page-table-main .table .table-th:nth-child(5),
.page-table-main .table .table-td:nth-child(5) {
  width: 10%;
} */

/* Presale table  */

.presale-table-main .table .table-th:nth-child(1),
.presale-table-main .table .table-td:nth-child(1) {
  width: 10% !important;
}

.presale-table-main .table .table-th:nth-child(2),
.presale-table-main .table .table-td:nth-child(2) {
  width: 17% !important;
}

.presale-table-main .table .table-th:nth-child(3),
.presale-table-main .table .table-td:nth-child(3) {
  width: 17% !important;
}

.presale-table-main .table .table-th:nth-child(4),
.presale-table-main .table .table-td:nth-child(4) {
  width: 15% !important;
}

.presale-table-main .table .table-th:nth-child(5),
.presale-table-main .table .table-td:nth-child(5) {
  width: 15% !important;
}

/* Transaction table  */

/* .transaction-table-main .table .table-th:nth-child(1),
.transaction-table-main .table .table-td:nth-child(1) {
  width: 16% !important;
}

.transaction-table-main .table .table-th:nth-child(2),
.transaction-table-main .table .table-td:nth-child(2) {
  width: 10% !important;
}

.transaction-table-main .table .table-th:nth-child(3),
.transaction-table-main .table .table-td:nth-child(3) {
  width: 11% !important;
}

.transaction-table-main .table .table-th:nth-child(4),
.transaction-table-main .table .table-td:nth-child(4) {
  width: 11% !important;
}

.transaction-table-main .table .table-th:nth-child(5),
.transaction-table-main .table .table-td:nth-child(5) {
  width: 16% !important;
}

.transaction-table-main .table .table-th:nth-child(6),
.transaction-table-main .table .table-td:nth-child(6) {
  width: 10% !important;
} */

/*  KYC-list Table */

.kyc-table-main .table .table-th:nth-child(1),
.kyc-table-main .table .table-td:nth-child(1) {
  width: 20% !important;
}

.kyc-table-main .table .table-th:nth-child(2),
.kyc-table-main .table .table-td:nth-child(2) {
  width: 14% !important;
}

.kyc-table-main .table .table-th:nth-child(6),
.kyc-table-main .table .table-td:nth-child(6) {
  width: 14% !important;
}

.admin-sub-title-main .admin-sub-title {
  color: #111827;
  font-size: 20px;
  line-height: 30px;
  font-family: "Poppins-Medium";
}

.view-btn-main .view-order-btn {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
}

.view-btn-main .view-order-btn:hover {
  height: 28px;
  font-size: 12px;
  line-height: 18px;
  min-width: 100px;
}

.admin-dashboard-table-row {
  border-bottom: 1px solid #d1d5db;
  padding: 20px 19px;
}

.admin-dash-box {
  border-radius: 8px;
  border: 1px solid #d1d5db;
}

.admin-dashboad-row {
  margin-bottom: 24px;
}

.display-row > * {
  padding: 0px 8px;
}

.display-row {
  margin-left: -8px;
  margin-right: -8px;
}

/* end admin dashboard page */

.table-lable-main {
  font-family: "Poppins-Regular";
  font-size: 12px;
  line-height: 18px;
}

.paid-lable-dots {
  background-color: #04ab47;
}

.paid-lable {
  color: #04ab47;
}

.table-lable-dots {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  margin-right: 4px;
}

.table-lable-flex {
  display: flex;
  align-items: center;
}

.failed-lable-dots {
  background-color: #e4004d;
}

.failed-lable {
  color: #e4004d;
}

.pending-lable-dots {
  background-color: #f39c12;
}

.pending-lable {
  color: #f39c12;
}

/* width */
.page-table-main .table-container::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
.page-table-main .table-container::-webkit-scrollbar-track {
  background: #d1d5db;
  border-radius: 5px;
}

/* Handle */
.page-table-main .table-container::-webkit-scrollbar-thumb {
  background: #8888885c;
}

/* Handle on hover */
.page-table-main .table-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* .MuiDataGrid-main::-webkit-scrollbar {
  width: 2px;
  height: 10px;
} */

/* Track */
/* .MuiDataGrid-main::-webkit-scrollbar-track {
  background: red;
  border-radius: 5px;
} */

/* Handle */
/* .MuiDataGrid-main:-webkit-scrollbar-thumb {
  background: red;
} */

/* Handle on hover */
/* .MuiDataGrid-main::-webkit-scrollbar-thumb:hover {
  background: red;
} */
/* start add user page */

.add-user-containt .add-basic-text {
  color: #111827;
  margin: 24px 0px 4px;
}

.add-user-containt .add-section-text {
  color: #6b7280;
  margin-bottom: 20px;
}

/* .add-user-input .form-control input {
  padding: 10px 15px;
  color: rgba(0, 0, 0, 0.6) !important;
  border: 0px;
  width: 100%;
} */

.add-user-input .form-control-textarea {
  min-width: 1015px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  min-height: 100px;
  resize: none;
}

.radio-main .radiogroup .MuiButtonBase-root.Mui-checked {
  color: #004dd1;
}

.radio-main .MuiFormControlLabel-label {
  color: #6b7280;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
}

.user-btn-flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 60px;
}

/* end add user page */

/*  start dropdown css  */

.dropdown-box .form-control {
  width: 100%;
  position: relative;
}

/* .dropdown-box .form-control .dropdown-select {
  border: 1px solid #d1d5db;
} */

/* .dropdown-box .form-control svg{
      display: none;
} */
.dropdown-select .MuiSelect-select {
  padding: 6px 15px;
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Poppins-Medium";
  display: flex;
}

/* .form-control fieldset {
  border: transparent !important;
  border-radius: 2px;
} */

.grey-down {
  position: absolute;
  top: 17.92px;
  right: 21.92px;
  height: 10px;
  width: 10px;
  object-fit: contain;
}

/* / end dropdown css / */

/* .discard-btn-main .discard-user-btn,
.discard-btn-main .discard-user-btn:hover {
  min-width: 83px;
  margin-right: 10px;
  min-height: 40px;
} */

/* .user-save-icon {
  height: 15px;
  width: 15px;
  margin-right: 8px;
  object-fit: contain;
} */

.save-btn-main .save-user-btn,
.save-btn-main .save-user-btn:hover {
  min-width: 87px;
  min-height: 36px;
}

/* .admin-tabs-main .admin-tab {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Regular";
  color: #111827;
  text-transform: capitalize;
  padding: 10px 15px;
} */

/* .admin-tabs-main .admin-tab.Mui-selected {
  color: #27170a;
  font-family: "Poppins-Medium";
} */

/* .admin-tabs-main .MuiTabs-indicator {
  background-color: #74540b;
  height: 2px;
} */

/* .admin-tabpanel .edit-highlight-text {
  color: #111827;
  font-family: "Poppins-Medium";
  margin-bottom: 4px;
} */

/* .admin-tabpanel .edit-para-text {
  margin-bottom: 24px;
  color: #6b7280;
} */

/* .admin-tabpanel > .MuiBox-root {
  padding: 24px 0px;
} */

/* .file-upload-btn-main .file-upload-btn,
.file-upload-btn-main .file-upload-btn:hover {
  background-color: #ffffff;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  min-width: 17px;
  padding: 0px;
  position: absolute;
  right: 0px;
  bottom: 13px;
  box-shadow: 0px 3px 6px #00000029;
} */

/* .file-upload-btn-main {
  position: relative;
  max-width: max-content;
} */

/* .upload-profile-img {
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
} */

/* .upload-icon-img {
  height: 10px;
  width: 10px;
  object-fit: contain;
} */

/* .edit-profile-flex {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
} */

/*  */

.user-pass {
  height: 36px;
}

/* .add-user-data-main {
  padding: 25px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #0000001a;
} */

.user-pass fieldset,
.form-group .Mui-focused fieldset {
  border-color: transparent !important;
  border-width: 1px !important;
}

/* .submit-btn-main .submit-user-btn,
.submit-btn-main .submit-user-btn:hover,
.comman-btn-main .primary-btn,
.comman-btn-main .primary-btn:hover {
  min-width: 95px;
  margin-right: 10px;
  background-color: #5a320a;
  color: #ffffff;
  min-height: 40px;
  padding: 6px 14px 5px;
  font-size: 12px;
  border: 1px solid #5a320a;
  line-height: 18px;
  border-radius: 4px;
  text-transform: capitalize;
} */

/* .dashboard-content .admin-page-title {
  font-size: 26px;
  font-family: "Poppins-SemiBold";
  color: #001436;
} */

/* edit profile */

/* .change-pass-main {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 20px;
  max-width: 514px;
  width: 100%;
} */

/* .change-pass-input-box .form-group .form-control input {
  border: 1px solid #d1d5db;
  border-radius: 4px;
  width: 100%;
  padding: 5.5px 15px;
  background-color: transparent;
} */

/* .change-pass-flex {
  margin-bottom: 20px;
} */

/* .add-user-btn {
  display: flex;
  align-items: center;
  background-color: #74540b;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  max-width: 100px;
  width: 100%;
  justify-content: center;
  min-width: 115px;
  font-family: "Poppins-Medium";
} */

/* .add-user-btn svg {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  object-fit: contain;
} */

/* .user-data-head
  .MuiDataGrid-main
  .MuiDataGrid-columnHeaders
  .MuiDataGrid-columnHeadersInner {
  width: 100%;
} */

/* .user-head-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-top: 10px;
}

.user-head-title h2 {
  line-height: 19px;
} */

.user-dashboard-link:visited {
  background-color: #f39c12;
  font-size: 50px;
}

/* .dashboard-header {
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  color: #ffffff;
  display: flex;
  padding: 10px 12px 10px;
  margin-bottom: 10px;
  align-items: center;
  width: 100%;
  text-decoration: none;
} */

/* .add-user-data-main .user-data-head {
  border: none;
} */

.menulist .MuiPaper-rounded {
  width: 10ch !important;
  left: 1170px !important;
  top: 260px !important;
  box-shadow: none !important;
}

.menulist .MuiPaper-rounded ul li {
  padding: 8px 12px;
}

.menu-option-li button,
.menu-option-li button:hover {
  color: #001a47;
  background: transparent;
  font-size: 12px;
  line-height: 18px;
  padding: 0;
  font-family: "Poppins-Medium";
  justify-content: flex-start;
  box-shadow: none;
  text-transform: capitalize;
}

.swal2-backdrop-show .swal2-show {
  padding: 15px;
}

.swal2-backdrop-show .swal2-icon-show {
  margin-top: 0;
}

.swal2-backdrop-show .swal2-show h2 {
  padding: 10px 0 0;
}

.swal2-backdrop-show .swal2-show .swal2-actions {
  margin: 10px 0 0;
  justify-content: flex-end;
}

/* .scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
} */

/* Track */
/* .scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1c4;
} */

/* Handle */
/* .scrollbar::-webkit-scrollbar-thumb {
  background: #d7cfcf40;
} */

/* Handle on hover */
/* .scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.userbar-tabs-main .userbar-tab .tab.Mui-selected {
  color: #7668fe;
}

.userbar-tabs-main .userbar-tab {
  font-size: 16px;
  line-height: 19px;
  font-family: "Poppins-Medium";
  color: #495463;
  text-transform: capitalize;
  margin-right: 45px;
  min-height: 0px;
  padding-left: 0px;
  min-width: max-content;
  padding-bottom: 10px;
}

.userbar-tabs-main .userbar-tabs {
  min-height: 0px;
  border-bottom: 1px solid #e6effb;
}

.userbar-tabs-main .MuiTabs-indicator {
  background-color: transparent;
  width: 92.6562px !important;
}

/* .user-data-head .MuiDataGrid-columnHeaderTitle {
  font-size: 15px;
  line-height: 20px;
  color: #495463;
  font-family: "Poppins-Medium";
  text-overflow: initial;
  overflow: initial;
} */

.admin-datepicker-flex .sale-field:first-child {
  margin-right: 20px;
}

.airdrop-section-main .dashboard-left-main {
  display: none;
}

/* .admin-sidebar-icon {
  height: 24px;
  width: 24px;
  object-fit: contain;
} */

/* .admin-mobile-menu-icon {
  display: none;
} */

.admin-sidebar-close {
  height: 20px;
  width: 20px;
  object-fit: contain;
  display: none;
}

/* .admin-text-field-main {
  margin-bottom: 16px;
  min-height: 65px;
  height: auto;
} */

/* .admin-text-field-main .admin-textfield {
  width: 100%;
} */

/* .admin-text-field-main .admin-textfield input {
  max-width: 523px;
  padding: 9px 15px;
  color: rgba(0, 0, 0, 0.6) !important;
  border: 1px solid #74540b42;
  width: 100%;
  border-radius: 6px;
} */

/* .admin-text-field-main .admin-textfield .MuiInputBase-root {
  border-radius: 3px;
} */

/* .presale-input .admin-textfield input {
  max-width: 660px !important;
} */

/* .privat-sale-datepicker input {
  padding: 8.5px 15px;
} */

.admin-textfield .MuiFormLabel-root {
  top: -6px;
}

.primary-btn-main .admin-private-btn {
  margin-top: 0px;
  max-width: 90px;
  height: 40px;
}

/* .admin-private-main {
  padding: 10px 0px 0px;
} */

/* .admin-sidebar-icons svg {
  height: 24px;
  width: 24px;
  object-fit: contain;
} */

.token-view-box {
  max-width: 500px;
  width: 100%;
  padding: 25px;
  border: 1px solid #d1d5db;
  border-radius: 10px;
}

.token-view-box-right {
  flex: 1;
}

.token-box-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d1d5db;
  margin-bottom: 10px;
}

.token-view-box .token-view-lables {
  font-size: 15px;
  line-height: 20px;
  color: #495463;
  font-family: "Poppins-Medium";
  padding-bottom: 10px;
}

/* .add-user-input {
  margin-bottom: 20px;
} */

.token-view-box .token-view-data {
  font-size: 12px;
  line-height: 18px;
  color: #495463;
  font-family: "Poppins-Regular";
}

.token-box-flex:last-child {
  margin-bottom: 0px;
}

/* .add-user-input .form-control {
  width: 100%;
} */

.add-user-input .form-control .MuiNativeSelect-select {
  padding: 7.154px 15px;
  border-radius: 3px;
}

/* .dashboard-content .add-user-page-title {
  margin-bottom: 12px;
} */

.dropdown-select input {
  padding: 10.5px 15px 8px !important;
}

/* .admin-sidebar-list .MuiAccordion-root {
  background-color: transparent;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins-Medium";
  color: #ffffff;
  box-shadow: none;
} */

/* .admin-sidebar-list .MuiAccordionSummary-expandIconWrapper svg {
  color: #fff;
} */

.private-sale-usd-box .MuiInput-root::before,
.private-sale-usd-box .MuiInput-root::after {
  border: transparent;
}

.private-sale-usd-box .MuiInputBase-input {
  text-align: right;
  font-size: 16px;
  line-height: 19px;
  font-family: "Urbanist-Regular";
}

.private-sale-usd-box .MuiInput-root:hover::before {
  border: transparent !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.private-card-wrap {
  flex-wrap: wrap;
}

.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
  outline-width: 0px;
  outline-offset: 0px;
}

/* .user-title-row,
.withdrawal-head-title {
  margin-bottom: 0px;
} */

/* .admin-sidebar-list .MuiAccordionDetails-root {
  padding: 0px 16px 0px !important;
} */

.transcation-popup {
  padding: 8px;
}

.transcation-popup input {
  margin: 8px;
}

.dropdown-adduser .MuiSelect-select {
  border: 1px solid #849ec1;
  width: 100%;
  padding: 11.45px 16px;
  background-color: transparent;
  border-radius: 14px;
  color: #08080a;
  font-size: 14px;
  line-height: 21px;
}

/* .error-text {
  color: red;
  font-family: "Poppins-Regular";
  font-size: 13px;
  line-height: 15px;
  margin-top: 3px;
} */

/* .admin-forgot-para a {
  color: #000000;
  text-decoration: none;
} */

/* .input-box-admin2 {
  margin-bottom: 0px !important;
} */

.btn-set-box {
  width: 100%;
}

.primary-btn-main-user button {
  background-color: #74540b;
  color: white;
}

/* .date-picker-mui {
  border: 1px solid #d1d5db !important;
  border-radius: 6px !important;
} */

.send-message-content {
  text-align: center;
}

/* .flex-input {
  display: flex;
  margin-bottom: 20px;
} */

/* .join-wait-input-set input {
  min-width: 700px !important;
} */

/* .set-join-btn-box {
  margin-left: 20px;
} */

/* 6-6 start */
/* .submit-btn-main {
  display: flex;
  justify-content: flex-start;
} */

/* .dashboard-right-main { */
/* flex: calc(100vw - 290px); */
/* flex: calc(100vw - 260px);
  overflow: auto; */
/* } */

/* 6-6 end */
/* 8-6 start */
/* .logo-text {
  color: #fff;
  font-family: "Poppins-SemiBold";
  font-size: 19px;
} */

/* .admin-side-logo-text {
  text-align: left;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 31px;
  justify-content: left !important;
} */

/* .res-admin-logo {
  display: none;
} */

/* .admin-side-logo-text .admin-sidebar-logo,
.admin-side-logo-text .logo-text {
  display: block;
} */

/* .admin-sidebar-main.active {
  left: -300px;
}

.admin-sidebar-main {
  height: 100%;
  left: 0px;
} */

/* .dashboard-main.active .dashboard-left-main {
  flex: 0;
} */

/* .dashboard-main.active .admin-header-main {
  left: 0;
} */

/* 8-6 end */
/* 9-6 start */
/* .admin-side-logo-text .admin-sidebar-logo {
  height: 40px;
  max-width: 64px;
  padding: 0;
  margin-top: 0;
} */

/* .res-admin-header .admin-header-row {
  justify-content: space-between;
}

.res-admin-header .admin-header-row button {
  padding: 0;
  display: flex;
  justify-content: start;
} */

/* 9-6 end */
/* 15-6 start */
/* .preasale-search {
  display: flex;
} */

/* .cus-search .user-search-box {
  position: relative;
  margin-right: 15px;
} */

/* .cus-search .user-search-box .search-grey-img {
  position: absolute;
  top: 16px;
  left: 16px;
} */

/* .cus-search input {
  padding-left: 35px !important;
} */

/* sidebar cms */
/* .admin-sidebar-listitem .expandless-icon,
.admin-sidebar-listitem .expandmore-icon {
  position: absolute;
  right: 10px;
  top: 10px;
} */

/* .admin-submenu-listitem-main .admin-sidebar-link {
  display: block;
}

.admin-sidebar-submenulist .admin-sidebar-link {
  margin-left: 10px;
} */

/* .admin-sidebar-submenulist
  .admin-sidebar-listitem:first-child
  .admin-sidebar-link {
  margin-top: 20px;
} */

/* .errorshow {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
} */

/* 15-6 end */
/* 16-6  start */
/* .user-search-box .form-group .form-control input {
  padding-top: 9px;
  border-radius: 5px;
  padding-bottom: 10px;
  line-height: 22px;
} */

/* .admin-sidebar-list-main ul li:hover a.dashboard-header {
  background: #5a320a;
  border-radius: 4px;
  color: #fff !important;
} */

/* .align-center {
  display: flex;
  align-items: center;
} */

.email-icon path {
  fill: #1a73e8;
}

/* .page-table-main .table-container {
  border-radius: 14px;
} */

/* a.admin-sidebar-link .align-center span {
  margin-left: 8px;
} */

/* .page-table-main .table .table-th:first-child,
.page-table-main .table .table-td:first-child {
  width: 12%;
  padding-left: 15px;
} */

.admin-dash-box .page-table-main .table {
  margin: 0;
}

.transactionlist table {
  min-width: 100%;
}

/* .box-shadow-none {
  box-shadow: none !important;
} */

/* 16-6 end  */
/* 19-6 start */
/* .card-main {
  box-shadow: 0 3px 6px #d5d2d2;
  padding: 15px;
  border: 1px solid #b3b3b387;
  border-radius: 9px;
} */

/* .charttitle {
  font-family: "Poppins-Medium";
  border-bottom: 1px solid #b3b3b3;
  padding-bottom: 15px;
} */

/* .xaxis-main .recharts-cartesian-axis-ticks .recharts-cartesian-axis-tick text {
  font-size: 11px;
  font-family: "Poppins-Regular";
}

.yaxis-main .recharts-cartesian-axis-tick text {
  font-size: 12px;
  font-family: "Poppins-Regular";
}

.line-chart-main .recharts-label-list text {
  font-family: "Poppins-Regular";
  font-size: 8px;
} */

/* .presale-table-main tr th,
.presale-table-main tr td {
  text-align: center;
} */
.transactionlist tr th:nth-child(4),
.transactionlist tr td:nth-child(4) {
  text-align: left;
  width: 5% !important;
}

/* .page-table-main tr th,
.page-table-main tr td {
  text-align: left !important;
} */

.page-table-main .table .table-td:first-child a {
  color: #000;
  text-decoration: none;
}

/* .custom-card {
  border: 1px solid #d7d7d7;
  padding: 15px;
  border-radius: 6px;
  padding-top: 0;
  box-shadow: 0 3px 6px #d5d2d2;
} */

/* .detailform-row {
  display: flex;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #0000001a;
} */

/* .detialform-title {
  font-family: "Poppins-medium";
  padding: 5px 5px 5px 15px;
  font-size: 14px;
  display: flex;
  align-items: center;
  background: #d9d8d8;
  border-radius: 5px 0px 0 5px;
  width: 140px;
} */

/* .detailform-content {
  font-size: 14px;
  font-family: "Poppins-Regular";
  padding: 5px 5px 5px 15px;
  line-height: 25px;
  display: flex;
  align-items: center;
} */

/* 19- 6  end  */
/* 20-6 start */
/* .user-detail-profile {
  width: 170px;
  object-fit: cover;
  height: 170px;
  border-radius: 5px;
} */

/* .user-detail-profile-main {
  width: 100%;
  display: flex;
  justify-content: center;
} */

/* .user-details-name p {
  text-align: center;
  font-family: "Poppins-SemiBold";
  font-size: 15px;
  margin-top: 14px;
} */

/* .user-detail-profile-section {
  padding-right: 20px;
} */

/* .userdetail-profilesec {
  padding-bottom: 20px;
} */

/* .view-icon {
  width: 20px;
  height: 20px;
} */

.mr-0 {
  margin-right: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

/* .tabpanelinner-body {
  padding: 15px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
} */

/* 20-6 end */
/* kyc design start */
.set-admin-upload {
  position: relative !important;
  max-width: 660px;
}

.set-position-btn2 {
  position: absolute;
  right: 4px;
  bottom: 4px;
}

.upload-btn-input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
  bottom: 0;
}

.primary-border-btn-main .upload-set-btn {
  height: 35px !important;
  min-width: 150px !important;
}

/* .mr-15 {
  margin-right: 15px;
} */

/* .mt-0 {
  margin-top: 0 !important;
} */

/* .waitlist-drop .MuiInputBase-colorPrimary {
  width: 209px;
} */

/* .waitlist-drop .MuiOutlinedInput-input {
  padding: 8px 15px;
  border: 1px solid #d1d5db;
} */

/* kyc design end */
/* .page-table-main .table .table-td:nth-child(2) a {
  text-decoration: none;
  color: #6b7280;
} */

/* .email-ellip {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
}

.email-ellip a {
  text-decoration: none;
  color: black;
} */

.waitlist-table tr th:first-child,
.waitlist-table tr td:first-child {
  width: 12% !important;
}

.waitlist-table tr th:nth-child(2),
.waitlist-table tr td:nth-child(2) {
  /* width: 10% !important; */
  width: 8% !important;
}

.waitlist-table tr th:nth-child(3),
.waitlist-table tr td:nth-child(3) {
  width: 1% !important;
}

.waitlist-table tr th:nth-child(4),
.waitlist-table tr td:nth-child(4) {
  width: 8% !important;
}

.waitlist-table tr th:nth-child(5),
.waitlist-table tr td:nth-child(5) {
  width: 8% !important;
}

.waitlist-table tr th:nth-child(6),
.waitlist-table tr td:nth-child(6) {
  width: 5% !important;
}

.waitlist-table tr th:nth-child(7),
.waitlist-table tr td:nth-child(7) {
  width: 8% !important;
}

.waitlist-table tr th:nth-child(8),
.waitlist-table tr td:nth-child(8) {
  width: 5% !important;
}

.waitlist-table tr th:nth-child(9),
.waitlist-table tr td:nth-child(9) {
  width: 11% !important;
}

/* .date-range-picker .date-picker-mui .MuiStack-root {
  padding-top: 3px;
} */

.date-range-picker .MuiInputBase-colorPrimary input {
  padding: 6.5px 14px;
  font-size: 13px;
  font-family: "Poppins-Medium";
  height: 23px;
}

/* .waitlist-table tr td:last-child .MuiIconButton-sizeMedium:last-child label {
  margin-right: 0 !important;
} */

/* .set-chart-drop {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */

/* .chart-drop .MuiOutlinedInput-input {
  padding: 8px 15px;
  border: 1px solid #d1d5db;
}

.chart-drop .MuiSelect-select {
  width: 150px !important;
  min-width: 150px !important;
} */

/* .presale-dash .user-dashboard-inner-box {
  background: linear-gradient(90deg, #6a4c38, #4d2a14de) !important;
} */

.prasale-tot-transaction .table .table-th:nth-child(2),
.prasale-tot-transaction .table .table-td:nth-child(2) {
  width: 19% !important;
}

.prasale-tot-transaction .table .table-th:nth-child(3),
.prasale-tot-transaction .table .table-td:nth-child(3) {
  width: 27% !important;
}

/* .admin-text-field-main-set {
  margin-bottom: 0px !important;
  min-height: auto !important;
  height: auto !important;
} */

/* .pagination-main .MuiTablePagination-actions .MuiIconButton-sizeMedium {
  padding: 0 !important;
} */

.adduser-btn {
  color: black !important;
  border: 0.5px solid gray;
}

/* .username-bold {
  font-weight: 600;
  color: black;
} */

/* table design start*/
/* .table-design-main tr th {
  background: #5a320a;
  color: #fff !important;
} */

/* .table-design-main table {
  border-collapse: separate;
  border-spacing: 0 9px;
} */

/* .table-design-main tr td {
  background: #2828281a;
  border-bottom: 0 !important;
} */

/* .table-design-main tr td:first-child,
.table-design-main tr th:first-child {
  border-radius: 6px 0 0 6px;
} */

/* .table-design-main tr td:last-child,
.table-design-main tr th:last-child {
  border-radius: 0 6px 6px 0;
} */

/* .table-design-main .table-th {
  padding: 17px 10px 17px 0px !important;
  font-family: "Poppins-SemiBold" !important;
  border-bottom: 0 !important;
} */

/* .table-design-main .table-th:first-child {
  padding-left: 15px !important;
} */

/* .table-design-main table {
  margin: 0 !important;
} */

.kyc-table tr .table-td {
  padding: 5px 10px 5px 0px;
}

/* table design end */
.kyc-image-view {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.kyc-content {
  border: 1px solid #d1d5db;
  border-radius: 5px;
  background: #e3e3e3;
  padding: 10px;
  font-size: 13px;
  font-family: "Poppins-regular";
}

.kyc-upload-doc {
  height: 200px;
  width: 200px;
  object-fit: contain;
}

/* ------------ */
.loader-bg-main {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #32323291;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  /* padding-left: 290px; */
  padding-left: 260px;
}
.loader-bg-main-lazy-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #32323291;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.loader-bg-main-loader {
}
/* .loader-inner-border {
  border-color: #e17025 #e17025 #f5aa16 #f5aa16;
  border-style: solid;
  border-width: 10px;
  border-radius: 22px;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  -webkit-animation: animNameloader 1s linear infinite;
  animation: animNameloader 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.loader-bg-main .logo-small-loader {
  width: 150px;
  height: 150px;
  object-fit: contain;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@keyframes animNameloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.loader-inner-bg-main {
  position: relative;
}

.headerlogo-login {
  max-width: 122px !important;
  height: 66px !important;
  margin-top: 5px;
}

.headerroundmainimg3 {
  position: absolute;
  width: 147px !important;
  height: 84px !important;
}

.headerroundmainimg2 {
  width: 111px !important;
  height: 110px !important;
  position: absolute;
  animation: rotating1 12s linear infinite;
}

.sorting-arrow {
  visibility: hidden;
}
.col-name:hover .sorting-arrow {
  visibility: visible;
}
/* .create-hover {
  display: flex;
  align-items: center;
} */

/* .create-hover span.create-hover-span {
  display: none;
} */

/* .page-table-main tr th:hover .create-hover span.create-hover-span {
  display: flex;
} */

/*withdraw modal css */
.referral-box {
  display: flex;
  padding: 8px 15px 0px;
  justify-content: space-between;
}

.referral-box .all-income {
  display: flex !important;
  justify-content: space-between !important;
}

.referral-box-code-exchange {
  padding-right: 30px;
}
.income-heading {
  margin: 0px 5px;
  padding: 0px 15px 0px;
  background-color: lightgray;
}

.title-name-upload {
  margin-bottom: 10px;
}
.verify-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}
.upload-img-content {
  border-radius: 4px;
  /* border: 1px solid #d1d5db; */
  /* position: relative; */
  display: flex;
}
.upload-img-content .upload-kyc-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.upload-pad .upload-kyc-img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.from_login_details .flex-div {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.right-submit-btn {
  display: flex;
  align-items: end;
  justify-content: end;
}

.user-search-box .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.user-search-box .form-group button {
  border-radius: 10px;
  background-image: linear-gradient(
    72.47deg,
    rgb(63 227 152) 12.16%,
    rgb(50 176 196) 86.47%
  );
  text-transform: none;
  font-size: 14px;
  font-family: "Poppins-Medium";
}
