.token-presale-background {
  background-image: linear-gradient(142deg, #000000bf, #7367f08c),
    url("../../../../assets/images/jpg/presale-bg2.jpg");
  background-size: 100% 100%;
  /* background-size: cover; */
  padding: 37px 0 30px;
  /* display: flex; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 96px);
}

.billionair-tokenpre-card {
  /* background: #fff; */
  /* border-radius: 40px; */
  /* box-shadow: inset 2px 2px 3px hsla(0, 0%, 100%, 0.2); */
  overflow: hidden;
  position: relative;
  /* background: linear-gradient(90deg, rgb(118 85 11), #523908); */
  border-radius: 6px;
  /* background: linear-gradient(90deg, #1acb41 0%, #7ed947 52%) !important; */
  background: #ffffff;
  height: 100%;
}

.billionair-tokenpre-card .billionair-tokenpre-body {
  /* padding: 48px 51px 48px 48px; */
  padding: 20px 25px;
  position: relative;
  z-index: 1;
}

.billionair-list-content .hero-token-presale {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #5b5865;
  display: -webkit-box;
  font-family: "Poppins-SemiBold";
  font-weight: 900;
  font-size: 22px;
  letter-spacing: -0.03em;
  line-height: 35px;
  margin-bottom: 15px;
  overflow: hidden;
  text-align: center;
}

.billionair-list-content .hero-token-details-presale {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #5b5865;
  display: -webkit-box;
  line-height: 1.3;
  font-weight: 100;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 16px;
  text-align: center;
  margin-bottom: 15px;
  overflow: hidden;
}
.mining-counter-time {
  top: 35% !important;
}
.time-flex-list {
  align-items: center;
  display: flex;
  justify-content: center;
  /* margin-bottom: 16px; */
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -38%);
}

.main-time-box {
  min-width: 34px;
  padding: 0 0px;
  text-align: center;
}

.main-time-box .time-numer-presale {
  color: #5b5865;
  font-family: "Poppins-Medium";
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 28.6px;
  margin-bottom: 0px;
}

.main-time-box .time-numer-presale-list {
  color: #5b5865;
  font-family: "Poppins-Regular";
  font-size: 9px;
  line-height: 12.28px;
}

.time-progress-bar-main {
  height: 14px;
  background: #ffffff38;
  max-width: 326px;
  margin: 0 auto 24.33px;
  border-radius: 100px;
  display: flex;
}

span.time-progress-bar {
  height: 14px;
  background: #fff;
  width: 20%;
  border-radius: 100px;
}

.time-presale-btns-list {
  margin: auto;
  max-width: 341px;
}

.time-presale-btns-list {
  margin: auto;
  max-width: 341px;
}

.new-time-presale-btns-list {
  display: flex;
  justify-content: center;
}

.white-paper-margin {
  margin-top: 26px;
}

.home-logo-text-color .logo-text {
  color: #fff;
  font-family: "Poppins-SemiBold";
  font-size: 19px;
}

.admin-header-logo,
.logo-token-left {
  height: 50px;
}
.time-presale-btns-list .btn-dark-comman {
  color: #ffffff !important;
  /* color: #5b5865 !important; */
}
/* .pre-btn{
    color: #fff !important;
    border: 1px solid #7367f0!important;
    background: #7367f0;
    padding: 10px 20px;
    border-radius: 6px;
} */
