.userdetail-profilesec {
    /* border-bottom: 1px solid #d9d8d8; */
    padding-bottom: 20px;
}
.user-detail-profile-section {
    padding-right: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #80808047;
    padding-bottom: 20px;
}
.user-detail-profile-main {
    /* width: 100%; */
    display: flex;
    justify-content: center;
}
.user-detail-profile {
    width: 100px;
    object-fit: cover;
    height: 100px;
    border-radius: 5px;
}
.user-details-name p {
    text-align: center;
    font-family: "Poppins-SemiBold";
    font-size: 15px;
    margin-top: 14px;
}
.detailform-row {
    display: flex;
    flex-direction: column;
    /* border-radius: 5px;
    box-shadow: 0px 3px 6px #0000001a; */
}
.detialform-title {
    font-family: "Poppins-medium";
    /* padding: 5px 5px 5px 15px; */
    font-size: 15px;
    display: flex;
    align-items: center;
    /* background: #f1f1f2; */
    /* background: #d9d8d8; */
    /* border-radius: 5px 0px 0 5px; */
    /* width: 140px; */
    color: #9a9ea7;
}
.detailform-content {
    font-size: 14px;
    font-family: "Poppins-Regular";
    padding: 5px 0px 5px 0px;
    line-height: 25px;
    display: flex;
    align-items: center;
}
.user-detail-main .MuiTableCell-th,
.user-detail-main .MuiTableCell-td{
    color: #726f7b;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Poppins-Medium';
}
.user-detail-main .MuiTableCell-td{
    font-family: 'Poppins-Regular';
}
.user-table-main .MuiPaper-elevation{
box-shadow: none;
}