/* sujal css */
.team-dashboard-inner-box{
    background: #ffffff;
  box-shadow: 0 4px 18px rgba(47, 43, 61, 0.1), 0 0 transparent, 0 0 transparent;
  height: 20px;
}
.total-dashboard-inner-box{
        /* border: 1px solid #D4F6FE; */
        max-width: 100%;
        width: 100%;
        /* height: 90px; */
        /* border-radius: 6px; */
        padding: 15px;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        border-radius: 6px;
        justify-content: center;
}
.total-dashboard-inner-box .team-dash-text{
    color: #5d5a68 !important;
    font-size: 13px;
    line-height: 20px;
    font-family: "Poppins-Regular";
    white-space: nowrap;
}
.tab-list{
    margin-left: 10px;
    background-color: white;
}
.tab-list .tab-button{
    color: #5d5a68;
    font-size: 12px;
    line-height: 35px;
    font-family: "Poppins-SemiBold";
    padding: 10px 20px; 
  }
  /* .tab-list .tab-button :focus ,.tab-button:active{
   color:red;
   text-decoration: none;  
   outline: auto;
  } */
  /* .tab-button :active{
    color:blueviolet;
  } */
  
  .tab-button.active-tab:focus,
.tab-button.active-tab:active,
.tab-button.active-tab:focus-visible {
  outline: none !important;
  /* Add other styles you want for the active tab */
}
.table-header th {
    color: #777e91;
    font-size: 13px;
    font-weight: 600;
    vertical-align: middle;
  }
  .table-rows td {
    font-family: IBM Plex Sans SemiBold;
    font-size: 14px;
    border-bottom: none;
  }
  .main-table-container .table-th:first-child{
    margin: 50px;
  }