.set-qr-code-new {
  /* height: 100px; */
  display: initial !important;
  margin-bottom: 50px;
}

.set-qr-code {
  /* height: 100px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-dash-box {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.admin-dashboard-table-row.user-dashboard-table-row {
  /* border-bottom: 1px solid #d1d5db; */
  padding: 15px 19px;
}

.set-code-box-main .admin-sub-title-main-user .admin-sub-title,
.referral-box .admin-sub-title-main-user .admin-sub-title {
  color: #111827;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins-SemiBold";
  min-width: 113px;
}

.copy-btn {
  background: #32b0c4;
  border-radius: 5px;
  display: flex !important;
}

.copy-btn-set {
  margin-left: 10px;
}

.copy-btn button svg path {
  fill: white;
}

.set-code-box-main {
  /* padding: 31px 20px; */
  display: flex;
  align-items: left;
  flex-direction: column;
  margin: 0;
}

.dash-referral-btn-main {
  margin-top: 20px;
  margin-bottom: 21px;
}

.text-copy-box {
  padding: 5px 5px 5px 15px;
  background: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.09);
  border-radius: 4px;
  /* max-width: 588px; */
  max-width: 600px;
  width: 100%;
}

.dash-text-copy-box {
  margin-right: 10px;
}

.res-primary-btn {
  display: flex !important;
}

.referral-box {
  display: flex;
  padding: 8px 15px 10px;
  justify-content: space-between;
}

.referral-box-code {
  padding-right: 15px;
}

.dash-token-value-box {
  height: 31px;
  padding: 10px;
  background: #e7e7e7;
  border-radius: 5px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dash-token-value-box .user-value-box {
  font-size: 17px;
  line-height: 23px;
}

.admin-dash-box {
  border-radius: 8px;
  border: 1px solid #d1d5db;
  padding: 0px 0px 20px 0px;
}

.set-count-box-top {
  /* margin-top: 10px; */
}

.text-copy-box .text-copy {
  font-size: 14px;
  line-height: 17px;
  color: #000;
  font-family: "Poppins-Medium";
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  padding-right: 10px;
}
.dash-text-copy {
  max-width: 400px;
}
.dash-referral-btn-main {
  margin: 5px 0 10px 0;
}
.dash-title-mb0 {
  margin-bottom: 10px;
}
.pl-0 {
  padding-left: 0 !important;
}
.dash-reff-title {
  margin: 10px 0;
}
.my-referral-main .dash-text-copy {
  max-width: 100%;
}

.group-btn {
  display: flex;
  justify-content: space-between;
}

.group-btn .export-btn-main {
  margin: 0px 10px;
}

.set-code-box-main .referral-link-btn {
  display: flex !important;
  justify-content: center;
  margin-top: 20px !important;
}

.referral-link-box {
  height: auto !important;
}

.remove-shadow {
  box-shadow: none !important;
  height: auto !important;
}
.remove-shadow .billionair-tokenpre-card {
  box-shadow: none !important;
}
