.response-main-card {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.response-main-card img {
    width: 400px;
    height: 400px;
}
.response-main-card h3 {
    text-align: center;
    margin: 10px 0px;
}