
@media screen and (min-width: 992px) and (max-width:1199px) {
.res-user-btn{
    flex-direction: row;
}
}
@media screen and (min-width: 768px) and (max-width: 991px){
    .res-user-btn{
        flex-direction: row;
    }
}
@media screen and (max-width:767px) {

}
